.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 105px;
  padding: 0 30px;
  z-index: 2000;
  transition: all 0.3s $ease;
  @include onlyMobile {
    height: 73px;
    padding: 0 15px;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: $white;
    transform: translateY(-100%);
    transition: all 0.3s $ease;
  }
  &-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0 20px 110px;
    z-index: 10;
    @include onlyMobile {
      align-items: flex-start;
      padding: 10px 0;
    }
  }
  &-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 140px;
    background: url(/assets/img/bg-logo.png) no-repeat bottom center;
    background-size: 100% auto;
    transition: all 0.3s $ease;
    @include onlyMobile {
      width: 80px;
      height: 90px;
    }
    &-gray {
      background: url(/assets/img/bg-logo-gray.png) no-repeat bottom center;
      background-size: 100% auto;
    }
    &-gold {
      background: url(/assets/img/bg-logo-gold.png) no-repeat bottom center;
      background-size: 100% auto;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 140px;
      transition: all 0.3s $ease;
      @include onlyMobile {
        width: 80px;
        height: 90px;
      }
      img {
        position: relative;
        top: -6%;
        width: 80px;
        height: auto;
        transition: all 0.3s $ease;
        @include onlyMobile {
          width: 56px;
        }
      }
    }
  }
  &-menu {
    margin-left: 20px;
    @include onlyMobile {
      display: none;
    }
    &-main {
      display: flex;
      margin-bottom: 7px;
      transition: all 0.3s $ease;
      li {
        margin-right: 10px;
        @include onlySmallDesktop {
          margin-right: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
        a {
          display: block;
          padding: 5px 15px;
          font-size: 12px;
          color: $black;
          transition: all 0.3s $ease;
          @include onlySmallDesktop {
            padding: 5px 10px;
          }
          &:hover {
            color: $blackhover;
            background: $graylight;
          }
        }
        &.is-active {
          a {
            background: $graylight;
          }
        }
      }
    }
    &-sub {
      display: flex;
      transition: all 0.3s $ease;
      li {
        margin-right: 5px;
        &:last-of-type {
          margin-right: 0;
        }
        a {
          padding: 5px 15px;
          color: $black;
          transition: all 0.3s $ease;
          @include onlySmallDesktop {
            padding: 5px 10px;
          }
          &:hover {
            color: $blackhover;
            background: $graylight;
          }
        }
        &.is-active {
          a {
            background: $graylight;
          }
        }
      }
    }
  }
  &-navi {
    flex: 1;
    transition: all 0.3s $ease;
    @include onlyMobile {
      padding-bottom: 5px;
    }
    ul {
      display: flex;
      justify-content: flex-end;
      li {
        margin-left: 20px;
        @include onlyMobile {
          margin-left: 10px;
        }
        a {
          display: block;
          text-align: center;
          color: $black;
          line-height: 1;
          transition: all 0.3s $ease;
          i {
            color: $yellow;
            font-size: 35px;
            transition: all 0.3s $ease;
          }
          span {
            display: block;
            margin-top: 3px;
            font-size: 10px;
            transition: all 0.3s $ease;
            transform: scale(0.95);
          }
          &:hover {
            color: $blackhover;
            i {
              color: $yellowlight;
            }
          }
        }
      }
    }
  }
  &-vertical {
    &-logo {
      width: 150px;
      height: 90px;
      background: none;
      @include onlyMobile {
        width: 41px;
        height: 73px;
      }
      a {
        flex-direction: column;
        width: 150px;
        height: 90px;
        padding-top: 12px;
        @include onlyMobile {
          width: 41px;
          height: auto;
          padding-top: 18px;
        }
        .l-header-vertical-logo-mark {
          width: 41px;
          height: 41px;
          transition: all 0.3s $ease;
        }
        .l-header-vertical-logo-type {
          width: 100%;
          margin-top: 11px;
          transition: all 0.3s $ease;
          @include onlyMobile {
            display: none;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }   
  }
  &-vertical2 {
    &-logo {
      width: 150px;
      height: 90px;
      background: none;
      @include onlyMobile {
        width: 160px;
        height: 73px;
      }
      a {
        flex-direction: column;
        width: 150px;
        height: 90px;
        padding-top: 12px;
        @include onlyMobile {
          flex-direction: row;
          width: 160px;
          height: 73px;
          padding: 0;
        }
        .l-header-vertical2-logo-mark {
          width: 41px;
          height: 41px;
          transition: all 0.3s $ease;
          @include onlyMobile {
            width: 35px;
            height: 35px;
          }
        }
        .l-header-vertical2-logo-type {
          width: 100%;
          margin-top: 11px;
          transition: all 0.3s $ease;
          @include onlyMobile {
            flex: 1;
            margin-top: 0;
            padding-left: 10px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }   
  }
}

.l-header.is-sticky {
  height: 68px;
  @include onlyMobile {
    height: 54px;
  }
  &:before {
    transform: translateY(0);
  }
  .l-header-inner {
    padding: 10px 0 10px 110px;
  }
  .l-header-logo {
    height: 95px;
    @include onlyMobile {
      height: 70px;
    }
    a {
      @include onlyMobile {
        height: 70px;
      }
      img {
        transform: scale(0.68) translateY(-30px);
        @include onlyMobile {
          transform: scale(0.75) translateY(0);
        }
      }
    }
  }
  .l-header-menu-main {
    opacity: 0;
    transform: translateY(-20px);
  }
  .l-header-menu-sub {
    transform: translateY(-29px);
  }
  .l-header-navi {
    transform: translateY(-10px);
    @include onlyMobile {
      transform: translateY(0);
    }
  }
  .l-header-logo-mark {
    @include onlyMobile {
      width: 45px;
      transform: translateY(-4px);
    }
  }
  .l-header-navi ul li a span {
    @include onlyMobile {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  .l-header-button-spnavi {
    @include onlyMobile {

    }
  }
  .l-header-button-spnavi-text {
    @include onlyMobile {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}

.l-header-vertical.is-sticky {
  .l-header-inner {
    padding-left: 110px;
  }
  .l-header-vertical-logo {
    @include onlyMobile {
      height: 54px;
    }
  }
  .l-header-vertical-logo a {
    @include onlyMobile {
      height: 54px;
      padding-top: 11px;
    }
  }
  .l-header-vertical-logo a .l-header-vertical-logo-type {
    opacity: 0;
    transform: translateY(-10px);
  }
  .l-header-vertical-logo a .l-header-vertical-logo-mark {
    transform: scale(1) translateY(-3px);
  }
  .l-header-vertical-logo a .l-header-vertical-logo-mark img {
    transform: scale(1);
  }
}

.l-header-vertical2.is-sticky {
  .l-header-inner {
    padding-left: 110px;
  }
  .l-header-vertical2-logo {
    @include onlyMobile {
      height: 54px;
    }
  }
  .l-header-vertical2-logo a {
    @include onlyMobile {
      height: 54px;
      padding-top: 11px;
    }
  }
  .l-header-vertical2-logo a .l-header-vertical2-logo-type {
    opacity: 0;
    transform: translateY(-10px);
    @include onlyMobile {
      opacity: 1;
      transform: translateY(-5px);
    }
  }
  .l-header-vertical2-logo a .l-header-vertical2-logo-type img {
    transform: scale(1) translateY(0);
  }
  .l-header-vertical2-logo a .l-header-vertical2-logo-mark {
    transform: scale(1) translateY(-3px);
  }
  .l-header-vertical2-logo a .l-header-vertical2-logo-mark img {
    transform: scale(1);
  }
}

.l-header.is-sticky + .l-spnavi {
  padding-top: 40px;
}

.l-header-button-spnavi {
  display: none;
  width: 45px;
  margin-left: 15px;
  font-size: 10px;
  text-align: center;
  @include onlyMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
  }
  &-text {  
    margin-top: 11px;
    line-height: 1;
    transition: all 0.3s $ease;
    z-index: 0;
    @include onlyMobile {
      transform: scale(0.95);
    }
  }
  button {
    position: relative;
    top: 2px;
    display: block;
    width: 30px;
    height: 23px;
    padding: 0;
    margin: 4px auto 0;
    border: none;
    background: none;
    z-index: 10;
    span {
      display: block;
      width: 100%;
      height: 2px;
      background: $yellow;
      transition: all 0.3s $ease;
      &:nth-child(1) {
        position: absolute;
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -1px;
      }
      &:nth-child(3) {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.l-spnavi {
  display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  padding-top: 60px;
	z-index: 1000;
  color: $white;
  opacity: 1;
  transition: padding .3s $ease;
  @include onlyMobile {
    display: block;
  }
  &[v-cloak] {
    display: none;
  }
  &-inner {
    position: relative;
    height: 100%;
    opacity: 1;
    overflow-y: auto;
    transition: all .3s $ease 0.2s;
	}
	&-menu {
		padding: 20px;
    ul {
      li {
        border-bottom: solid 1px rgba(255,255,255,0.15);
        &:last-of-type {
          border-bottom: none;
        }
        a {
          display: block;
          padding: 15px;
          color: $yellowlight4;
        }
      }
    }
	}
  > span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 20%;
    display: block;
    background: $black;
    transform: translateX(0);
    transition: all .3s $ease;
    &:nth-child(1) {
      top: 0;
      transition: all .3s $ease;
    }
    &:nth-child(2) {
      top: 20%;
      transition: all .3s $ease 0.05s;
    }
    &:nth-child(3) {
      top: 40%;
      transition: all .3s $ease 0.1s;
    }
    &:nth-child(4) {
      top: 60%;
      transition: all .3s $ease 0.15s;
    }
    &:nth-child(5) {
      top: 80%;
      transition: all .3s $ease 0.2s;
    }
  }
}

.show-spnavi {
  .l-header {
    background: $white;
  }
  .l-header-button-spnavi {
    button {
      transform: translateX(10px);
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translateY(15px);
          transform-origin: center;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translateY(-15px);
          transform-origin: center;
        }
      }
    }
  }
}

.l-spnavi-enter-active, .l-spnavi-leave-active {
  transition: all .3s $ease;
  > span {
    transform: translateX(-100%);
  }
  .l-spnavi-inner {
    opacity: 0;
  }
}

.l-spnavi-enter, .l-spnavi-leave-to {
  opacity: 0;
  transition: all .3s $ease 0.5s;
}
