.is-ready {
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.5s $ease;
}

.animated {
    opacity: 1;
    transform: translateY(0px);
}

.is-pc {
    display: block;
    @include onlyMobile {
        display: none;
    }
}

.is-sp {
    display: none;
    @include onlyMobile {
        display: block;
    }
}
