.c-hero {
    position: relative;
    width: 100%;
    height: calc( 100vh - 105px );
    min-height: 640px;
    padding: 60px 120px;
    background: url(/assets/img/hero-bg.jpg) no-repeat bottom right;
    background-size: cover;
    @include onlyMobile {
        height: 220vw;
        min-height: inherit;
        padding: 6vw 8vw 8vw;
        background: url(/assets/img/hero-bg-sp.jpg) no-repeat center center;
        background-size: cover;
    }
    &-top-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(/assets/img/hero-hakase.png) no-repeat top left;
        background-size: cover;
        @include onlyMobile {
            background: url(/assets/img/hero-hakase-sp.png) no-repeat top center;
            background-size: cover;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(-10px);
            transition: all 1s $ease;
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
    }
    &-video {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 45vw;
            height: 100%;
            align-items: center;
            @include onlyMobile {
                justify-content: space-between;
                width: 100%;
            }
        }
        &-point {
            margin-bottom: 30px;
            ul {
                display: flex;
                justify-content: space-between;
                li {
                    width: 30%;
                }
            }
        }
        &-wrap {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            box-shadow: 0 0 40px -5px rgba(4,52,89,0.5);
            overflow: hidden;
            @include onlyMobile {
                display: none !important;
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 1s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &-text {
            font-size: 1.11vw;
            line-height: 2;
            text-align: justify;
            text-justify: inter-ideograph;
            @include onlyMobile {
                margin-bottom: 0;
                padding: 3.5vw;
                font-size: 3.3vw;
                background: rgba(255,255,255,0.85);
                border: solid 1px $yellow;
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 1s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
        &-title {
            margin-bottom: 30px;
            font-size: 2.1vw;
            line-height: 1.5;
            white-space: nowrap;
            @include onlyMobile {
                margin-bottom: 5vw;
                font-size: 7vw;
                line-height: 1.3;
            }
            br {
                display: none;
                @include onlyMobile {
                    display: block;
                }
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(-10px);
                span {
                    display: inline-block;
                    opacity: 0;
                    transform: translateY(-10px);
                    transition: all 0.5s $ease;
                    &:nth-child(1) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease;
                    }
                    &:nth-child(2) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.05s;
                    }
                    &:nth-child(3) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.1s;
                    }
                    &:nth-child(4) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.15s;
                    }
                    &:nth-child(5) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.2s;
                    }
                    &:nth-child(6) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.25s;
                    }
                    &:nth-child(7) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.3s;
                    }
                    &:nth-child(8) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.35s;
                    }
                    &:nth-child(9) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.4s;
                    }
                    &:nth-child(10) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.45s;
                    }
                    &:nth-child(11) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.5s;
                    }
                    &:nth-child(12) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.55s;
                    }
                    &:nth-child(13) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.6s;
                    }
                    &:nth-child(14) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.65s;
                    }
                    &:nth-child(15) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.7s;
                    }
                    &:nth-child(16) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.75s;
                    }
                    &:nth-child(17) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.8s;
                    }
                    &:nth-child(18) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.85s;
                    }
                    &:nth-child(19) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 0.9s;
                    }
                    &:nth-child(20) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 0.95s;
                    }
                    &:nth-child(21) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 1s;
                    }
                    &:nth-child(22) {
                        transform: translateY(10px);
                        transition: all 0.5s $ease 1.05s;
                    }
                    &:nth-child(23) {
                        transform: translateY(-10px);
                        transition: all 0.5s $ease 1.1s;
                    }
                }
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
                span {
                    opacity: 1;
                    transform: translateY(0px) !important;
                }
            }
        }
        &-button-sp {
            display: none !important;
            width: 100%;
            @include onlyMobile {
                display: block !important;
            }
            .u-button {
                display: block;
                width: 100%;
                padding: 13px 20px;
            }
        }
        &-youtube {
            position: relative;
            width: 90%;
            padding-top: 50.25%;
            margin: 0 auto;
            @include onlyMobile {
                width: 100%;
            }
            iframe {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.c-hero-202204 {
    .c-hero-top-image {
        @include onlyMobile {
            background: url(/assets/img/hero-hakase-sp-202204.png) no-repeat center 47vw;
            background-size: cover;
        }
    }
    .c-hero-video-inner {
        @include onlyMobile {
            justify-content: flex-start;
        }
    }
    .c-hero-video-point {
        @include onlyMobile {
            margin: 0 -15px 15px;
        }
        ul {
            li {
                @include onlyMobile {
                    width: 30%;
                } 
            }
        }
    }
    .c-hero-video-text {
        @include onlyMobile {
            margin-top: auto;
        }
    }
}

.c-navigation-top {
    display: flex;
    overflow-x: hidden;
    @include onlyMobile {
        flex-wrap: wrap;
    }
    &-title {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        width: 20%;
        color: $white;
        text-align: center;
        font-size: 30px;
        background: linear-gradient(135deg, $blacklight, $black);
        line-height: 1.5;
        @include onlyMobile {
            width: 100%;
            padding: 20px;
        }
        &:before {
            position: absolute;
            top: 6px;
            left: 6px;
            display: block;
            content: "";
            width: calc( 100% - 14px );
            height: calc( 100% - 14px );
            border: solid 1px rgba(255,255,255,0.25);
        }
        small {
            font-size: 17px;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(-10px);
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
    }
    &-navi {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        @include onlyMobile {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: inherit;
            justify-content: inherit;
            width: 100%;
        }
        &-item {
            position: relative;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 25%;
            padding: 30px 20px 50px 20px;
            text-align: center;
            background: $yellow;
            color: $white;
            border-right: solid 1px rgba(255,255,255,0.3);
            transition: all 0.3s $ease;
            @include onlyMobile {
                width: 50%;
                height: auto;
                padding: 40px 15px 45px 15px;
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &:last-of-type {
                border-right: none;
            }
            &:nth-child(2n) {
                @include onlyMobile {
                    border-right: none;
                }
            }
            &:nth-child(1) {
                transition: all 0.5s $ease;
                @include onlyMobile {
                    border-bottom: solid 1px rgba(255,255,255,0.3);
                }
            }
            &:nth-child(2) {
                transition: all 0.5s $ease 0.1s;
                @include onlyMobile {
                    border-bottom: solid 1px rgba(255,255,255,0.3);
                }
            } 
            &:nth-child(3) {
                transition: all 0.5s $ease 0.2s;
            }
            &:nth-child(4) {
                transition: all 0.5s $ease 0.3s;
            }  
            &:before {
                position: absolute;
                bottom: 20px;
                left: 50%;
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                margin-left: -5px;
                border-right: solid 1px $white;
                border-bottom: solid 1px $white;
                transform: rotate(45deg);
                transition: all 0.3s $ease;
                @include onlyMobile {
                    bottom: 25px;
                }
            }
            &:hover {
                background: $yellowlight;
                &:before {
                    bottom: 13px;
                    @include onlyMobile {
                        bottom: 20px;
                    }
                }
            }
            &-title {
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.5;
            }
            &-note {
                font-size: 12px;
                line-height: 1.4;
            }
            &-number {
                position: absolute;
                top: 50%;
                left: -15px;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                color: $yellow;
                line-height: 30px;
                z-index: 10;
                @include onlyMobile {
                    top: 0;
                    left: 50%;
                    margin-top: 0;
                    margin-left: -15px;
                }
                strong {
                    position: relative;
                    z-index: 10;
                }
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: $white;
                    z-index: 0;
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.c-about {
    position: relative;
    padding: 80px 30px;
    background: url(/assets/img/top-bg-about3.jpg) no-repeat bottom center fixed;
    background-size: cover;
    color: $white;
    @include onlyMobile {
        padding: 60px 15px;
        background: url(/assets/img/top-bg-about2-sp.jpg) no-repeat center left;
        background-size: cover;
    }
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
    }
    &-inner {
        position: relative;
        max-width: 880px;
        margin: 0 auto 90px auto;
        z-index: 10;
        @include onlyMobile {
            margin-bottom: 30px;
        }
        .c-box-video {
            border: solid 1px $white;
            background: none;
            &-inner {
                border: none;
            }
        }
    }
    &-lead {
        p {
            margin-bottom: 30px;
            text-align: justify;
            text-justify: inter-ideograph;
            strong {
                font-weight: 700;
            }
        }
    }
    &-detail {
        &-image {
            margin-bottom: 30px;
        }
        &-step {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            @include onlyMobile {
                flex-direction: column;
            }
            &-item {
                position: relative;
                display: flex !important;
                align-items: center;
                justify-content: center;
                width: 30.5%;
                padding: 30px;
                background: $yellow;
                color: #fff;
                text-align: center;
                font-size: 17px;
                line-height: 1.5;
                @include onlyMobile {
                    width: 100%;
                    padding: 15px;
                    margin-bottom: 30px;
                }
                &.is-ready {
                    opacity: 0;
                    transform: translateX(10px);
                }
                &.animated {
                    opacity: 1;
                    transform: translateX(0);
                }
                &:nth-child(2) {
                    transition: all 0.5s $ease 0.1s;
                    @include onlyMobile {
                        transition: all 0.5s $ease;
                    }
                }
                &:nth-child(3) {
                    transition: all 0.5s $ease 0.2s;
                    @include onlyMobile {
                        transition: all 0.5s $ease;
                    }
                }
                &:before {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    display: block;
                    content: "";
                    width: calc( 100% - 12px );
                    height: calc( 100% - 12px );
                    border: solid 1px rgba(255,255,255,0.7);
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -15px;
                    border: 15px solid transparent;
                    border-left: 15px solid $yellow;
                    @include onlyMobile {
                        top: 100%;
                        left: 50%;
                        margin-top: 0;
                        margin-left: -15px;
                        transform: rotate(90deg);
                    }
                }
                &:last-of-type{
                    &:after {
                        display: none;
                    }
                }
                &-number {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    color: $yellow;
                    @include onlyMobile {
                        top: -5px;
                        left: -5px;
                    }
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $white;
                        transform: rotate(45deg);
                    }
                    &:after {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        display: block;
                        content: "";
                        width: 24px;
                        height: 24px;
                        border: solid 1px $yellow;
                        transform: rotate(45deg);
                    }
                    strong {
                        position: relative;
                    }
                }
                &-label {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    display: block;
                    padding: 5px 10px;
                    background: $white;
                    color: $yellow;
                    font-size: 10px;
                    text-align: center;
                    line-height: 1.1;
                    @include onlyMobile {
                        top: -5px;
                        left: -5px;
                    }
                    strong {
                        display: block;
                        position: relative;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .c-title-lv1 {
        text-align: center;
    }
    .u-list-number {
        padding: 20px 20px 20px 3em;
        border: solid 1px #fff;
    }
    &-flow {
        ul {
            display: flex;
            @include onlyMobile {
                flex-direction: column;
            }
            li {
                position: relative;
                width: 31%;
                margin-right: 3.5%;
                padding: 30px;
                background: $yellow;
                color: $white;
                text-align: center;
                font-weight: 700;
                line-height: 1.5;
                @include onlyMobile {
                    width: 100%;
                    margin-bottom: 30px;
                    padding: 20px;
                    margin-right: 0;
                }
                &:nth-child(3n) {
                    margin-right: 0;
                    @include onlyMobile {
                        margin-bottom: 0;
                    }
                    &:before {
                        display: none;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -15px;
                    border: 15px solid transparent;
                    border-left: 15px solid $yellow;
                    @include onlyMobile {
                        top: 100%;
                        left: 50%;
                        margin-top: 0;
                        margin-left: -15px;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}

.vm--container {
    z-index: 2000 !important;
    .vm--overlay {
        background: rgba(0,0,0,0.5);
    }
    .vm--modal {
        background: none !important;
        box-shadow: none !important;
    }
    .c-modal-video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.c-title-lv1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    font-size: 30px;
    line-height: 1.5;
    @include onlyMobile {
        flex-direction: column;
        margin-bottom: 30px;
        font-size: 23px;
    }
    &-icon {
        margin-right: 15px;
        @include onlyMobile {
            margin-bottom: 10px;
        }
        img {
            width: auto;
            height: 70px;
            @include onlyMobile {
                height: 50px;
            }
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(-10px);
            transition: all 0.5s $ease;
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
    }
    &-text {
        &.is-ready {
            opacity: 0;
            transform: translateX(10px);
            transition: all 0.5s $ease;
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
        small {
            display: block;
            font-size: 12px;
            text-align: center;
        }
    }
}

.c-title-lv2 {
    margin-bottom: 30px;
    font-size: 23px;
    line-height: 1.5;
    text-align: center;
    @include onlyMobile {
        font-size: 20px;
    }
    small {
        display: block;
        font-size: 12px;
    }
    &.is-ready {
        opacity: 0;
        transform: translateX(-10px);
        span {
            display: inline-block;
            opacity: 0;
            transform: translateY(-10px);
            transition: all 0.5s $ease;
            &:nth-child(1) {
                transform: translateY(-10px);
                transition: all 0.5s $ease;
            }
            &:nth-child(2) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.05s;
            }
            &:nth-child(3) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.1s;
            }
            &:nth-child(4) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.15s;
            }
            &:nth-child(5) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.2s;
            }
            &:nth-child(6) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.25s;
            }
            &:nth-child(7) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.3s;
            }
            &:nth-child(8) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.35s;
            }
            &:nth-child(9) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.4s;
            }
            &:nth-child(10) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.45s;
            }
            &:nth-child(11) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.5s;
            }
            &:nth-child(12) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.55s;
            }
            &:nth-child(13) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.6s;
            }
            &:nth-child(14) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.65s;
            }
            &:nth-child(15) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.7s;
            }
            &:nth-child(16) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.75s;
            }
            &:nth-child(17) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.8s;
            }
            &:nth-child(18) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.85s;
            }
            &:nth-child(19) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 0.9s;
            }
            &:nth-child(20) {
                transform: translateY(10px);
                transition: all 0.5s $ease 0.95s;
            }
            &:nth-child(21) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 1s;
            }
            &:nth-child(22) {
                transform: translateY(10px);
                transition: all 0.5s $ease 1.05s;
            }
            &:nth-child(23) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 1.1s;
            }
            &:nth-child(24) {
                transform: translateY(10px);
                transition: all 0.5s $ease 1.15s;
            }
            &:nth-child(25) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 1.2s;
            }
            &:nth-child(26) {
                transform: translateY(10px);
                transition: all 0.5s $ease 1.25s;
            }
            &:nth-child(27) {
                transform: translateY(-10px);
                transition: all 0.5s $ease 1.3s;
            }
            &:nth-child(28) {
                transform: translateY(10px);
                transition: all 0.5s $ease 1.35s;
            }
        }
    }
    &.animated {
        opacity: 1;
        transform: translateX(0);
        span {
            opacity: 1;
            transform: translateY(0px) !important;
        }
    }
    &-left {
        text-align: left;
    }
}

.c-title-top {
    display: flex;
    align-items: center;
    justify-content: center;
    @include onlyMobile {
        flex-direction: column;
    }
    &-label {
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 220px;
        height: 220px;
        padding: 15px;
        background: linear-gradient(135deg, $yellowlight2, $yellow);
        color: #fff;
        font-size: 17px;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;
        @include onlyMobile {
            width: 260px;
            height: auto;
            padding: 20px 15px;
            margin-bottom: 30px;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(-10px);
            transition: all 0.5s $ease;
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
        small {
            display: block;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
        }
        &:before {
            position: absolute;
            top: 4px;
            left: 4px;
            display: block;
            content: "";
            width: calc( 100% - 10px);
            height: calc( 100% - 10px);
            border: solid 1px $white;
        }
        &-number {
            position: absolute;
            top: 0;
            left: 0;
            width: 220px;
            height: 220px;
            display: flex;
            align-item: center;
            justify-content: center;
            font-size: 180px;
            font-weight: 400;
            line-height: 190px;
            color: rgba(0,0,0,0.08);
            z-index: -1;
            @include onlyMobile {
                width: 100%;
                height: 100%;
                font-size: 80px;
                line-height: 80px;
            }
        }
    }
    &-detail {
        flex: 1;
        padding-left: 30px;
        @include onlyMobile {
            padding-left: 0;
        }
        .c-title-lv1 {
            display: block;
            margin-bottom: 20px;
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
        &-lead {
            text-align: justify;
            text-justify: inter-ideograph;
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
    &-nolabel {
        .c-title-top-detail {
            padding-left: 0;
            .c-title-lv1  {
                text-align: center;
            }
        }
    }
}

.c-box-image {
    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include onlyMobile {
            flex-direction: column;
        }
        &-reverse {
            flex-direction: row-reverse;
            @include onlyMobile {
                flex-direction: column;
            }
        }
        &-photo {
            position: relative;
            width: 46%;
            @include onlyMobile {
                width: 100%;
            }
            .c-bg-image01 {
                width: 100%;
                padding-top: 75%;
                background: url(http://placehold.jp/1200x1200.png) no-repeat;
                background-size: cover;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-tool01 {
                width: 100%;
                padding-top: 100%;
                background: url(/assets/img/top-tool01.png) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-tool02 {
                width: 100%;
                padding-top: 100%;
                background: url(/assets/img/top-tool02.png) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-original-image02 {
                width: 100%;
                padding-top: 75%;
                background: url(/assets/img/original-image02.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-original-image03 {
                width: 100%;
                padding-top: 75%;
                background: url(/assets/img/original-image03.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-original-image04 {
                width: 100%;
                padding-top: 75%;
                background: url(/assets/img/original-image04.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-rental-image01 {
                width: 100%;
                padding-top: 75%;
                background: url(/assets/img/rental-image01-s.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-antoniotarontino-image02 {
                width: 100%;
                padding-top: 75%;
                background: url(/assets/img/HT220_wcase.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 100%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-antoniotarontino-image03 {
                width: 100%;
                padding-top: 100%;
                background: url(/assets/img/HT220_front.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 140%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            .c-bg-antoniotarontino-image04 {
                width: 100%;
                padding-top: 100%;
                background: url(/assets/img/HT220_back.jpg) no-repeat;
                background-size: 100% auto;
                @include onlyMobile {
                    padding-top: 140%;
                    background-position: center center;
                    margin-bottom: 15px;
                }
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(-10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &-number {
                position: absolute;
                width: 80px;
                height: 80px;
                top: -20px;
                left: -20px;
                line-height: 80px;
                font-size: 40px;
                text-align: center;
                color: $yellow;
                background: $white;
                border-radius: 50%;
                z-index: 10;
                @include onlyMobile {
                    width: 40px;
                    height: 40px;
                    top: -10px;
                    left: -10px;
                    line-height: 40px;
                    font-size: 20px;
                }
                span {
                    position: relative;
                }
                &:after {
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    display: block;
                    content: "";
                    width: 70px;
                    height: 70px;
                    border: solid 1px $yellow;
                    border-radius: 50%;
                    @include onlyMobile {
                        width: 35px;
                        height: 35px;
                        top: 1px;
                        left: 1px;
                    }
                }
            }
        }
        &-detail {
            width: 46%;
            font-size: 17px;
            line-height: 2;
            @include onlyMobile {
                width: 100%;
                font-size: 15px;
                line-height: 1.5;
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &-text {
                padding: 30px 0;
                border-top: solid 1px $yellow;
                border-bottom: solid 1px $yellow;
                @include onlyMobile {
                    padding: 15px 0;
                }
            }
        }
    }
}

.c-box-video {
    margin: 0 auto;
    padding: 3px;
    background: linear-gradient(135deg, $yellowlight2, $yellow);
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        text-align: center;
        border: solid 1px $white;
        @include onlyMobile {
            flex-direction: column;
            padding: 20px;
        }
    }
    .c-title-lv1 {
        margin-bottom: 0;
        font-size: 23px;
        @include onlyMobile {
            margin-bottom: 15px;
            font-size: 17px;
        }
        &-small {
            font-size: 20px;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(-10px);
            span {
                display: inline-block;
                opacity: 0;
                transform: translateY(-10px);
                transition: all 0.5s $ease;
                &:nth-child(1) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease;
                }
                &:nth-child(2) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.05s;
                }
                &:nth-child(3) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.1s;
                }
                &:nth-child(4) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.15s;
                }
                &:nth-child(5) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.2s;
                }
                &:nth-child(6) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.25s;
                }
                &:nth-child(7) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.3s;
                }
                &:nth-child(8) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.35s;
                }
                &:nth-child(9) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.4s;
                }
                &:nth-child(10) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.45s;
                }
                &:nth-child(11) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.5s;
                }
                &:nth-child(12) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.55s;
                }
                &:nth-child(13) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.6s;
                }
                &:nth-child(14) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.65s;
                }
                &:nth-child(15) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.7s;
                }
                &:nth-child(16) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.75s;
                }
                &:nth-child(17) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.8s;
                }
                &:nth-child(18) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.85s;
                }
                &:nth-child(19) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 0.9s;
                }
                &:nth-child(20) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 0.95s;
                }
                &:nth-child(21) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1s;
                }
                &:nth-child(22) {
                    transform: translateY(10px);
                    transition: all 0.5s $ease 1.05s;
                }
                &:nth-child(23) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1.1s;
                }
                &:nth-child(24) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1.2s;
                }
                &:nth-child(25) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1.3s;
                }
                &:nth-child(26) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1.4s;
                }
                &:nth-child(26) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1.5s;
                }
                &:nth-child(27) {
                    transform: translateY(-10px);
                    transition: all 0.5s $ease 1.6s;
                }
            }
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
            span {
                opacity: 1;
                transform: translateY(0px) !important;
            }
        }
    }
    &-body {
        @include onlyMobile {
            padding-left: 0;
            width: 100%;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(10px);
            transition: all 0.5s $ease;
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
        .u-button-large {
            @include onlyMobile {
                width: 100%;
            }
        }
    }
}

.c-lesson {
    position: relative;
    padding: 80px 30px;
    background: url(/assets/img/top-bg-lesson2.jpg) no-repeat bottom center fixed;
    background-size: cover;
    color: $white;
    @include onlyMobile {
        padding: 60px 15px;
        background: url(/assets/img/top-bg-lesson.jpg) no-repeat bottom center;
        background-size: cover;
    }
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.7);
    }
    &-inner {
        position: relative;
        max-width: 1080px;
        margin: 0 auto;
        z-index: 10;
    }
    &-buttons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
            position: relative;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 200px;
            height: 200px;
            margin: 0 30px;
            background: $yellow;
            color: $white;
            border-radius: 100%;
            font-size: 17px;
            font-weight: 700;
            font-family: "Noto Serif JP", "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
            border: none;
            cursor: pointer;
            transition: all 0.3s $ease;
            @include onlyMobile {
                flex-direction: column;
                width: 200px;
                height: 200px;
                margin: 0 auto 30px;
                font-size: 15px;
            }
            &:nth-child(3) {
                @include onlyMobile {
                    margin-bottom: 0;
                }
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &:before {
                position: absolute;
                top: -6px;
                left: -6px;
                display: block;
                content: "";
                width: 210px;
                height: 210px;
                border: solid 1px $yellow;
                border-radius: 50%;
                transition: all 0.3s $ease;
                @include onlyMobile {
                    width: 210px;
                    height: 210px;
                }
            }
            &:hover {
                background: $yellowhover;
                &:before {
                    top: -12px;
                    left: -12px;
                    width: 222px;
                    height: 222px;
                    border: solid 1px $yellowhover;
                    @include onlyMobile {
                        top: -9px;
                        left: -9px;
                        width: 216px;
                        height: 216px;
                    }
                }
            }
            &-number {
                font-size: 12px;
            }
            &-title {
                text-align: center;
                line-height: 1.4;
            }
            &-label {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                padding: 7px 15px;
                font-size: 12px;
                background: linear-gradient(135deg, $blacklight, $black);
                color: $white;
                text-align: center;
                @include onlyMobile {
                    padding: 7px 10px;
                    font-size: 12px;
                }
            }
        }
    }
}

.c-price {
    position: relative;
    padding: 80px 30px;
    background: url(/assets/img/top-bg-price.jpg) no-repeat bottom center fixed;
    background-size: cover;
    color: $white;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
    }
    &-inner {
        position: relative;
        max-width: 880px;
        margin: 0 auto;
        z-index: 10;
    }
    .u-table-wrap {
        table {
            border-top: none;
            border-left: none;
            tr {
                th {
                    &:last-of-type {
                        border-right: none;;
                    }
                }
                td {
                    &:first-of-type {
                        border-left: none;
                    }
                    &:last-of-type {
                        border-right: none;
                    }
                }
                &:last-of-type {
                    td {
                        &:first-of-type {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(10px);
            transition: all 0.5s $ease;
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

.c-image-sample {
    display: flex;
    flex-wrap: wrap;
    li {
        width: 48%;
        margin: 0 4% 10px 0;
        &:nth-child(2n) {
            margin-right: 0;
        }
        &.c-image-sample-mb0 {
            margin-bottom: 0;
        }
    }
    
}

.c-box-size {
    display: flex !important; 
    @include onlyMobile {
        flex-direction: column;
    }
    &-image {
        order: 2;
        display: flex;
        align-items: center;
        width: 30%;
        margin: 0 2%;
        @include onlyMobile {
            width: 100%;
            order: 1;
            margin: 0 0 15px 0;
        }
    }
    &-item {
        width: 33%;
        padding: 30px;
        border: solid 1px $yellow;
        @include onlyMobile {
            width: 100%;
            padding: 15px;
        }
        &-ex {
            order: 1;
            @include onlyMobile {
                margin-bottom: 15px;
                order: 2;
            }
        }
        &-choice {
            order: 3;
            @include onlyMobile {
                order: 3;
            }
        }
        .c-title-lv2 {
            margin-bottom: 15px;
        }
    }
}

.c-recommend {
    position: relative;
    padding: 80px 30px;
    background: url(/assets/img/top-bg-recommend2.jpg) no-repeat bottom center fixed;
    background-size: cover;
    color: $white;
    @include onlyMobile {
        padding: 60px 15px;
        background: url(/assets/img/top-bg-recommend.jpg) no-repeat bottom center;
        background-size: cover;
    }
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.7);
    }
    &-inner {
        position: relative;
        max-width: 1080px;
        margin: 0 auto;
        z-index: 10;
    }
    &-list {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -60px;
            @include onlyMobile {
                flex-direction: column;
            }
            li {
                display: flex !important;
                align-items: center;
                width: 30%;
                margin: 0 5% 60px 0;
                @include onlyMobile {
                    width: 100%;
                    margin: 0 0 15px 0;
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }    
        &-icon {
            width: 15px;
            margin-bottom: 10px;
            img {
                width: 15px;
                height: auto;
                margin: 0 auto;
                @include onlyMobile {
                    width: 12px;
                }
            }
        }
        &-text {
            flex: 1;
            padding-left: 15px;
            font-size: 17px;
            line-height: 1.5;
            text-align: justify;
            text-justify: inter-ideograph;
            @include onlyMobile {
                font-size: 15px;
            }
        }
    }
}

.c-box-flow {
    padding: 60px 30px;
    border: solid 1px $yellow;
    @include onlyMobile {
        padding: 30px;
    }
    &-list {
        ul {
            display: flex;
            @include onlyMobile {
                flex-direction: column;
            }
            li {
                position: relative;
                width: 24%;
                display: flex !important;
                align-items: center;
                justify-content: center;
                margin-right: 4%;
                padding: 30px 15px;
                background: $yellow;
                color: $white;
                text-align: center;
                font-size: 17px;
                @include onlyMobile {
                    width: 100%;
                    margin: 0 0 30px 0;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -15px;
                    border: 15px solid transparent;
                    border-left: 15px solid $yellow;
                    @include onlyMobile {
                        top: 100%;
                        left: 50%;
                        margin-top: 0;
                        margin-left: -15px;
                        transform: rotate(90deg);
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                    @include onlyMobile {
                        margin-bottom: 0;
                    }
                    &::before {
                        display: none;
                    }
                }
                &:nth-child(1) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease;  
                }
                &:nth-child(2) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease 0.1s;  
                    @include onlyMobile {
                        transition: all 0.5s $ease; 
                    }
                }
                &:nth-child(3) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease 0.2s;  
                    @include onlyMobile {
                        transition: all 0.5s $ease; 
                    }
                }
                &:nth-child(4) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease 0.3s; 
                    @include onlyMobile {
                        transition: all 0.5s $ease; 
                    }
                }
                &.animated {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
        &-number {
            position: absolute;
            top: -10px;
            left: -10px;
            display: block;
            width: 30px;
            height: 30px;
            font-size: 17px;
            line-height: 30px;
            text-align: center;
            color: $yellow;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 30px;
                height: 30px;
                background: $white;
                transform: rotate(45deg);
            }
            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
                display: block;
                content: "";
                width: 25px;
                height: 25px;
                border: solid 1px $yellow;
                transform: rotate(45deg);
            }
            span {
                position: relative;
            }
        }
    }
}

.c-faq {
    &-item {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: solid 1px $yellow;
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        &-question {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &-icon {
                font-size: 25px;
                font-weight: bold;
                color: $yellow;
                line-height: 1;
            }
            &-text {
                flex: 1;
                padding-left: 10px;
                font-size: 20px;
            }
        }
        &-answer {
            padding-left: 30px;
            &-video {
                max-width: 600px;
                margin: 30px auto 0;
                &-wrap {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 56.25%;
                    overflow: hidden;
                    iframe {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

.c-message {
    position: relative;
    padding: 80px 30px;
    background: url(/assets/img/istockphoto-1131760814-2048x2048.jpg) no-repeat bottom center;
    background-size: cover;
    color: $white;
    @include onlyMobile {
        padding: 60px 15px;
        background: url(/assets/img/istockphoto-1131760814-2048x2048-sp.jpg) no-repeat bottom center;
        background-size: cover;
    }
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.7);
    }
    &-inner {
        position: relative;
        max-width: 1080px;
        margin: 0 auto;
        z-index: 10;
    }
    .c-title-top {
        text-align: center;
    }
    &-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        @include onlyMobile {
            flex-direction: column-reverse;
            margin-bottom: 30px
        }
        &-reverse {
            flex-direction: row-reverse;
            @include onlyMobile {
                flex-direction: column-reverse;
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        &-image {
            width: 24%;
            @include onlyMobile {
                width: 100%;
                margin-bottom: 15px;
            }
        }
        &-text {
            position: relative;
            width: 70%;
            @include onlyMobile {
                width: 100%;
            }
        }
    }
}

.c-footer-banner {
    position: sticky;
    bottom: 0;
    left: 0;
    height: 74px;
    z-index: 100;
    transform: translateY(74px);
    transition: all 0.5s $ease;
    @include onlyMobile {
        height: 66px;
        transform: translateY(66px);
    }
    &.is-sticky {
        transform: translateY(0px);
    }
    &-inner {
        display: flex;
        justify-content: center;
        height: 100%;
        margin: 0 auto;
        padding: 10px 15px;
        background: linear-gradient(135deg, $yellowlight2, $yellow);
        @include onlyMobile {
            background: none;
            flex-direction: column;
            padding: 7px 8px 8px 8px;
        }
    }
    &-menu {
        display: flex;
        @include onlyMobile {
            display: none;
            justify-content: space-between;
            margin-bottom: 5px;
        }
        &-item {
            position: relative;
            display: flex;
            align-items: stretch;
            padding: 10px 15px;
            margin-right: 10px;
            background: $white;
            color: $yellow;
            line-height: 1.5;
            text-align: center;
            font-size: 12px;
            @include onlyMobile {
                justify-content: center;
                width: 32%;
                margin: 0;
                padding: 8px 8px;
                font-size: 12px;
                line-height: 1.3;
            }
            &:nth-child(1) {
                @include onlyMobile {
                    width: 28%;
                }
            }
            &:nth-child(2) {
                @include onlyMobile {
                    width: 40%;
                }
            }
            &:nth-child(3) {
                @include onlyMobile {
                    width: 28%;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 3px;
                height: 100%;
                background: linear-gradient(90deg, $yellowhover, $yellow);
            }
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                small {
                    font-size: 10px;
                    @include onlyMobile {
                        font-size: 10px;
                    }
                }
            }
            br {
                display: none;
                @include onlyMobile {
                    display: inline;
                }
            }
        }
    }
    &-button {
        display: flex;
        align-items: stretch;
        @include onlyMobile {
            width: 100%;
            height: 100%;
        }
        .u-button-large {
            min-width: inherit;
            padding: 17px 30px 17px 20px;
            font-size: 15px;
            font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
            font-weight: bold;
            @include onlyMobile {
                width: 60%;
                padding: 18px 20px 18px 15px;
                font-size: 12px;
            }
        }
    }
    &-pagetop {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 55px;
        height: calc( 100% - 20px );
        background: $black;
        border-radius: 50%;
        transition: all 0.5s $ease;
        @include onlyMobile {
            width: 46px;
        }
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            content: "";
            width: 12px;
            height: 12px;
            margin: -6px 0 0 -6px;
            border-top: solid 1px $white;
            border-right: solid 1px $white;
            transform: rotate(-45deg);
            transition: all 0.5s $ease;
        }
        &:after {
            position: absolute;
            top: 65%;
            left: 50%;
            display: block;
            content: "";
            width: 16px;
            height: 1px;
            margin-left: -8px;
            background: $white;
        }
        &:hover {
            background: $blackhover;
            &:before {
                margin-top: -10px;
            }
        }
    }
    &-recommend {
        position: absolute;
        left: 10px;
        top: 10px;
        height: calc( 100% - 20px );
        padding: 10px 15px;
        font-size: 10px;
        text-align: center;
        color: $white;
        background: $black;
        line-height: 1;
        transition: all 0.3s $ease;
        @include onlyMobile {
            display: none;
        }
        &:before {
            position: absolute;
            top: 3px;
            left: 3px;
            display: block;
            content: "";
            width: calc( 100% - 8px );
            height: calc( 100% - 8px );
            border: solid 1px rgba(255,255,255,0.5);
            transition: all 0.3s $ease;
        }
        &:hover {
            background: $blackhover;
            &:before {
                top: 4px;
                left: 4px;
                width: calc( 100% - 10px );
                height: calc( 100% - 10px );
                border: solid 1px rgba(255,255,255,1);
            }
        }
        &-icon {
            display: block;
            i {
                font-size: 20px;
                @include onlyMobile {
                    font-size: 15px;
                }
            }
        }
    }
}

.c-footer-banner-202204 {
    height: 100px;
    transform: translateY(100px);
    .c-footer-banner-button .u-button-large {
        width: 560px;
        padding: 34px 30px 34px 20px;
        font-size: 17px;
        text-align: left;
        @include onlyMobile {
            width: 75%;
            padding: 34px 30px 34px 20px;
            font-size: 13px;
        }
    }
    .c-footer-banner-pagetop {
        top: 23px;
        width: 55px;
        height: 55px;
    }
  .c-footer-banner-button-text {
    display: flex;
    flex-direction: column;
    strong {
      font-size: 18.5px;
    }
    small {
      font-size: 12px;
    }
  }
}

.modal-enter-active, .modal-leave-active {
    transition: opacity 0.3s $ease;
}

.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.c-modal {
    width: 100%;
    max-width: 800px;
    max-height: 95%;
    overflow-y: auto;
    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        background: rgba(0,0,0,0.75);
        z-index: 9999;
    }
    &-body {
        width: 100%;
        height: 100%;
    }
    &-normal {
        position: relative;
        padding: 30px;
        background: #fff;
        @include onlyMobile {
            padding: 15px;
        }
        &:before {
            position: absolute;
            top: 4px;
            left: 4px;
            display: block;
            content: "";
            width: calc( 100% - 10px );
            height: calc( 100% - 10px );
            border: solid 1px $yellowlight;
        }
        &-inner {
            position: relative;
        }
        &-title {
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: solid 1px $yellow;
            font-size: 25px;
            text-align: center;
            line-height: 1.5;
            @include onlyMobile {
                margin-bottom: 15px;
                padding-bottom: 15px;
                font-size: 20px;
            }
        }
        &-subtitle {
            margin-bottom: 15px;
            font-size: 20px;
            line-height: 1.5;
            @include onlyMobile {
                margin-bottom: 15px;
                font-size: 17px;
            }
        }
        &-categories {
            ul {
                li {
                    display: inline-block;
                    margin: 0 3px 5px 0;
                }
            }
        }
    }
    &-instructor {
        &-head {
            margin-bottom: 30px;
            @include onlyMobile {
                margin-bottom: 15px;
            }
            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                li {
                    margin-left: 15px;
                    @include onlyMobile {
                        font-size: 12px;
                    }
                    i {
                        position: relative;
                        top: 3px;
                        font-size: 20px;
                        @include onlyMobile {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
        &-list {
            ul {
                li {
                    display: flex;
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: solid 1px $yellowlight4;
                    @include onlyMobile {
                        flex-direction: column;
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
            &-image {
                width: 25%;
                @include onlyMobile {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            &-detail {
                flex: 1;
                padding-left: 20px;
                @include onlyMobile {
                    padding-left: 0;
                }
                &-name {
                    margin-bottom: 15px;
                    span {
                        display: inline-block;
                        margin-right: 15px;
                        ruby {
                            font-size: 17px;
                            rt {
                                font-size: 10px;
                            }
                        }
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    &-onlinelesson {
        &-list {
            margin-bottom: 30px;
            ul {
                li {
                    display: flex;
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: solid 1px $yellowlight4;
                    @include onlyMobile {
                        flex-direction: column;
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
            &-image {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 150px;
                height: 100px;
                background: $yellow;
                color: $white;
                font-size: 60px;
                @include onlyMobile {
                    flex-direction: column;
                    margin: 0 auto 15px;
                }
                &-number {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    font-size: 20px;
                    text-align: center;
                    color: $yellow;
                    line-height: 30px;
                    @include onlyMobile {
                        top: -5px;
                    }
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $white;
                        transform: rotate(45deg);
                    }
                    &:after {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        display: block;
                        content: "";
                        width: calc( 100% - 6px);
                        height: calc( 100% - 6px);
                        border: solid 1px $yellow;
                        transform: rotate(45deg);
                    }
                    strong {
                        position: relative;
                        z-index: 10;
                    }
                }
            }
            &-detail {
                flex: 1;
                padding-left: 20px;
                @include onlyMobile {
                    padding-left: 0;
                }
                &-title {
                    margin-bottom: 10px;
                    font-size: 17px;
                }
                &-text {
                    margin-bottom: 10px;
                    line-height: 1.5;
                }
                &-note {
                    font-size: 12px;
                    line-height: 1.5;
                    color: $yellow;
                }
                &-button {
                    margin-top: 20px;
                }
            }
        }
        &-footer {
            padding: 30px;
            border: solid 1px $yellow;
            @include onlyMobile {
                padding: 15px;
            }
        }
    }
    &-rental {
        &-list {
            margin-bottom: 30px;
            ul {
                li {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: solid 1px $yellowlight4;
                    @include onlyMobile {
                        flex-direction: column;
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
            &-image {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 280px;
                @include onlyMobile {
                    width: 100%;
                    margin-bottom: 15px;
                }
                &-number {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    font-size: 20px;
                    text-align: center;
                    color: $yellow;
                    line-height: 30px;
                    @include onlyMobile {
                        top: -5px;
                    }
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $white;
                        transform: rotate(45deg);
                    }
                    &:after {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        display: block;
                        content: "";
                        width: calc( 100% - 6px);
                        height: calc( 100% - 6px);
                        border: solid 1px $yellow;
                        transform: rotate(45deg);
                    }
                    strong {
                        position: relative;
                        z-index: 10;
                    }
                }
            }
            &-detail {
                flex: 1;
                padding-left: 20px;
                @include onlyMobile {
                    padding-left: 0;
                }
                &-title {
                    margin-bottom: 10px;
                    font-size: 17px;
                }
                &-text {
                    margin-bottom: 10px;
                    line-height: 1.5;
                }
                &-note {
                    font-size: 12px;
                    line-height: 1.5;
                    color: $yellow;
                }
                &-button {
                    margin-top: 20px;
                }
            }
        }
        &-footer {
            padding: 30px;
            border: solid 1px $yellow;
            @include onlyMobile {
                padding: 15px;
            }
        }
    }
    &-form {
        position: relative;
        padding: 30px;
        background: #fff;
        @include onlyMobile {
            padding: 15px;
        }
        &:before {
            position: absolute;
            top: 4px;
            left: 4px;
            display: block;
            content: "";
            width: calc( 100% - 10px );
            height: calc( 100% - 10px );
            border: solid 1px $yellowlight;
        }
        &-title {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: solid 1px $yellow;
            font-size: 25px;
            text-align: center;
            @include onlyMobile {
                font-size: 20px;
            }
        }
        &-body {
            position: relative;
        }
        &-item {
            display: flex;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: solid 1px $graylight2;
            @include onlyMobile {
                flex-direction: column;
            }
            &-label {
                width: 220px;
                padding-top: 3px;
                text-align: right;
                @include onlyMobile {
                    width: 100%;
                    padding-top: 0;
                    margin-bottom: 10px;
                    text-align: left;
                }
                .required {
                    position: relative;
                    top: -1px;
                    padding: 3px 10px;
                    font-size: 10px;
                    background: $yellow;
                    color: $white;
                }
            }
            &-input {
                flex: 1;
                padding-left: 15px;
                @include onlyMobile {
                    padding-left: 0;
                }
                &-multiple {
                    display: flex;
                    justify-content: space-between;
                    input {
                        width: 48%;
                    }
                }
                input[type="text"],
                input[type="email"],
                input[type="password"] {
                    padding: 7px 10px;
                    border: solid 1px $yellow;
                    background: $graylightbg;
                    transition: all 0.3s $ease; 
                    &:focus {
                        border: solid 1px $yellowlight;
                    background: $white;
                    }
                    &.input-large {
                        width: 100%;
                    }
                }
                &-note {
                    margin-top: 5px;
                    text-align: right;
                    font-size: 12px;
                    line-height: 1.5;
                }
            }
            &-check {
                margin-bottom: 30px;
                text-align: center;
                a {
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
                @include onlyMobile {
                    font-size: 13px;
                }
            }
            &-submit {
                text-align: center;
            }
        }
    }
    &-video {
        position: relative;
        max-width: 680px;
        margin: 0 auto;
        &-wrap {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            overflow: hidden;
            iframe {
                width: 100% !important;
                height: 100% !important;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &-image {
        position: relative;
    }
    &-point {
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: solid 1px $yellowlight;
            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            &-image {
                width: 30%;
            }
            &-detail {
                flex: 1;
                padding-left: 30px;
                font-size: 17px;
            }
        }
    }
    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        @include onlyMobile {
            top: 10px;
            right: 10px;
        }
        &:before {
            position: absolute;
            top: 15px;
            left: -5px;
            display: block;
            content: "";
            width: 30px;
            height: 2px;
            background: $white;
            transform: rotate(45deg);
            transform-origin: center;
        }
        &:after {
            position: absolute;
            top: 15px;
            left: -5px;
            display: block;
            content: "";
            width: 30px;
            height: 2px;
            background: $white;
            transform: rotate(-45deg);
            transform-origin: center;
        }
    }
}

.c-modal-button {
    position: relative;
    display: block;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s $ease;
    img {
        transition: all 0.3s $ease; 
    }
    i {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 25px;
        color: $bluedark;
        transition: all 0.3s $ease;
    }
    &-label {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        display: block;
        background: rgba(0,0,0,0.5);
        color: $white;
        font-size: 10px;
        text-align: center;
    }
    &:hover {
        i {
            color: $bluehover;
        }
        img {
            transform: scale(1.05);
        }
    }
}

.c-content-top {
    display: flex;
    @include onlyMobile {
        flex-direction: column;
    }
    &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        padding: 30px;
        color: $white;
        line-height: 1.5;
        text-align: center;
        background: linear-gradient(135deg, $yellowlight2, $yellow);
        @include onlyMobile {
            width: 100%;
            height: auto;
            padding: 20px;
        }
        strong {
            margin-bottom: 5px;
            font-size: 30px;
            font-weight: 400;
            @include onlyMobile {
                margin-bottom: 0;;
                font-size: 23px;
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(-10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &.c-content-top-title-small {
                font-size: 23px;
            }
        }
        small {
            font-size: 13px;
            &.is-ready {
                opacity: 0;
                transform: translateX(-10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
    &-excerpt {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        padding: 30px 300px 30px 30px;
        color: $white;
        font-size: 17px;
        background: url(/assets/img/bg-content-top.jpg) no-repeat center right;
        background-size: cover;
        @include onlyMobile {
            padding: 30px 35vw 30px 20px;
            font-size: 15px;
        }
        &-campaign {
            padding: 120px 350px 120px 60px;
            @include onlyMobile {
                padding: 60px 30vw 60px 20px;
            }
            h1 {
                font-size: 30px;
                @include onlyMobile {
                    font-size: 20px;
                }
            }
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
            width: 80%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0));
        }
        &-inner {
            position: relative;
            z-index: 10;
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

.c-list-entries {
    margin-bottom: 60px;
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -30px;
        @include onlyMobile {
            flex-direction: column;
        }
        li {
            width: 30%;
            margin: 0 5% 30px 0;
            @include onlyMobile {
                width: 100%;
                margin: 0 0 30px 0;
            }
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    &-image {
        position: relative;
        margin-bottom: 10px;
        overflow: hidden;
        img {
            transition: all 0.3s $ease; 
        }
        &-pickup {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 3px 10px;
            background: linear-gradient(135deg, $yellowlight2, $yellow);
            font-size: 10px;
            color: $white;
        }
    }
    &-title {
        margin-bottom: 10px;
    }
    &-category {
        font-size: 12px;
        text-align: right;
    }
    &-date {
        font-size: 12px;
    }
    a {
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
    &-small {
        ul {
            counter-reset: ranking;
            li {
                position: relative;
                border-bottom: solid 1px $graylight2;
                &:first-of-type {
                    border-top: solid 1px $graylight2;
                }
                a {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 0;
                    transition: all 0.5s $ease;
                    &:hover {
                        background: $graylightbg;
                    }
                }
                &:after {
                    position: absolute;
                    top: 15px;
                    left: 0;
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: $yellow;
                    color: $white;
                    font-size: 12px;
                    text-align: center;
                    line-height: 18px;
                    counter-increment: ranking;
                    content: counter(ranking);
                }
            }
        }
        &-image {
            width: 30%;
            overflow: hidden;
        }
        &-detail {
            flex: 1;
            padding-left: 10px;
            &-title {
                margin-bottom: 5px;
                font-size: 12px;
                line-height: 1.2;
            }
            &-date {
                font-size: 10px;
                text-align: right;
            }
        }
    }
}

.c-pager {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0;
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 45px;
                background: $yellow;
                color: $white;
                border-radius: 50%;
                transition: all 0.3s $ease;
                &:hover {
                    background: $yellowhover;
                }
            }
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 45px;
                background: $graylight;
                border-radius: 50%;
            }
        }
    }
}

.c-breadcrumb {
    padding: 10px 30px;
    background: $graylight;
    @include onlyMobile {
        padding: 15px 20px;
    }
    ul {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        @include onlyMobile {
            font-size: 10px;
        }
        li {
            margin-left: 30px;
            a {
                position: relative;
                &:before {
                    position: absolute;
                    top: 50%;
                    right: -15px;
                    display: block;
                    content: "";
                    width: 5px;
                    height: 5px;
                    margin-top: -2.5px;
                    border-top: solid 1px $black;
                    border-right: solid 1px $black;
                    transform: rotate(45deg);
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            &:last-of-type {
                a {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.c-entry {
    display: flex;
    justify-content: space-between;
    @include onlyMobile {
        flex-direction: column;
    }
    &-primary {
        width: 70%;
        @include onlyMobile {
            width: 100%;
        }
        &-title {
            margin-bottom: 30px;
            font-size: 23px;
            line-height: 1.5;
            @include onlyMobile {
                font-size: 20px;
            }
        }
        &-sns {
            margin-bottom: 30px;
            ul {
                display: flex;
                justify-content: flex-end;
            }
        }
        &-mainimage {
            margin-bottom: 30px;
            text-align: center;
        }
        &-body {
            h1 {
                margin-bottom: 30px;
                font-size: 23px;
                line-height: 1.5;
                @include onlyMobile {
                    font-size: 20px;
                }
            }
            h2 {
                margin-bottom: 30px;
                padding-bottom: 15px;
                font-size: 20px;
                line-height: 1.5;
                border-bottom: solid 1px $yellow;
                @include onlyMobile {
                    font-size: 17px;
                }
            }
            h3 {
                position: relative;
                padding-left: 30px;
                margin-bottom: 30px;
                font-size: 17px;
                line-height: 1.5;
                @include onlyMobile {
                    font-size: 15px;
                }
                &:before {
                    position: absolute;
                    top: 0.8em;
                    left: 0;
                    display: block;
                    content: "";
                    width: 20px;
                    height: 1px;
                    background: $yellow;
                }
            }
            h4 {
                margin-bottom: 30px;
                font-size: 15px;
                font-weight: 700;
                line-height: 1.5;
                @include onlyMobile {
                    font-size: 13px;
                }
            }
            p {
                margin-bottom: 30px;
                line-height: 1.75;
                @include onlyMobile {
                    font-size: 13px;
                }
                &.u-mb-small {
                    margin-bottom: 10px;
                }
            }
            ul {
                margin-bottom: 30px;
                @include onlyMobile {
                    font-size: 13px;
                }
                li {
                    padding-left: 12px;
                    margin-bottom: 7px;
                    position: relative;
                    line-height: 1.5;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &:before {
                        position: absolute;
                        top: 0.7em;
                        left: 0;
                        display: block;
                        content: "";
                        width: 5px;
                        height: 5px;
                        background: $yellow;
                    }
                }
            }
            ol {
                margin-bottom: 30px;
                padding-left: 1em;
                list-style: decimal;
                list-style-position: outside;
                @include onlyMobile {
                    font-size: 13px;
                }
                &.u-mb-small {
                    margin-bottom: 15px;
                }
                li {
                    margin-bottom: 7px;
                    position: relative;
                    line-height: 1.5;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &.u-lineheight-normal {
                        line-height: 1.8;
                    }
                }
            }
            blockquote {
                padding: 15px;
                margin: 0 0 30px 0;
                background: $graylightbg;
                @include onlyMobile {
                    font-size: 13px;
                }
            }
        }
    }
    &-secondary {
        width: 25%;
        @include onlyMobile {
            width: 100%;
        }
        &-banner {
            margin-bottom: 30px;
        }
        &-section {
            padding-top: 15px;
            margin-bottom: 30px;
            border-top: solid 2px $yellow;
            &:last-of-type {
                margin-bottom: 0;
            }
            &-title {
                margin-bottom: 15px;
                font-weight: 700;
                &-nomargin {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.c-tabs {
    display: flex;
    border-bottom: solid 1px $yellow;
    li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        background: $yellow;
        color: $white;
        font-size: 13px;
        text-align: center;
        border-right: solid 1px $white;
        cursor: pointer;
        transition: all 0.5s $ease;
        &:hover {
            background: $yellowhover;
        }
        &:last-of-type {
            border-right: none;
        }
        &.is-active {
            background: $white;
            color: $black;
        }
    }
}

.c-form {
    &-checkboxies {
        li {
            margin-bottom: 5px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-item {
        display: flex;
        max-width: 980px;
        margin: 0 auto 30px;
        padding-bottom: 30px;
        border-bottom: solid 1px $graylight2;
        @include onlyMobile {
            flex-direction: column;
        }
        &-noborder {
            padding-bottom: 0;
            border-bottom: none;
        }
        &-label {
            width: 220px;
            padding-top: 3px;
            text-align: right;
            @include onlyMobile {
                width: 100%;
                padding-top: 0;
                margin-bottom: 10px;
                text-align: left;
            }
            .required {
                position: relative;
                top: -1px;
                padding: 3px 10px;
                font-size: 10px;
                background: $yellow;
                color: $white;
            }
        }
        &-select {
            padding-top: 2px;
        }
        &-input {
            flex: 1;
            padding-left: 15px;
            @include onlyMobile {
                padding-left: 0;
            }
            &-multiple {
                display: flex;
                justify-content: space-between;
                input {
                    width: 48%;
                }
            }
            input[type="text"],
            input[type="email"],
            input[type="password"] {
                padding: 7px 10px;
                border: solid 1px $yellow;
                background: $graylightbg;
                transition: all 0.3s $ease; 
                &:focus {
                    border: solid 1px $yellowlight;
                    background: $white;
                }
                &.input-large {
                    width: 100%;
                }
                &.input-small {
                    width: 80px;
                }
                &.is-error {
                    border: solid 1px $red;
                    background: $redbg
                }
            }
            textarea {
                width: 100%;
                height: 200px;
                padding: 7px 10px;
                border: solid 1px $yellow;
                background: $graylightbg;
                transition: all 0.3s $ease; 
                &:focus {
                    border: solid 1px $yellowlight;
                    background: $white;
                }
                &.is-error {
                    border: solid 1px $red;
                    background: $redbg
                }
            }
            select {
                font-size: 17px;
            }
            &-note {
                margin-top: 5px;
                text-align: right;
                font-size: 12px;
                line-height: 1.5;
            }
            &-radios {
                li {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 5px;
                    label {
                        margin-right: 10px;
                        opacity: 0.3;
                    }
                    p {
                        padding-top: 5px;
                        line-height: 1.4;
                        opacity: 0.3;
                    }
                    select {
                        opacity: 0.3;
                    }
                    input {
                        margin-top: 8px;
                        margin-right: 3px;
                        &:checked  {
                            + label {
                                opacity: 1;
                            }
                            ~ p {
                                opacity: 1;
                            }
                            ~ select {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        &-check {
            margin-bottom: 30px;
            text-align: center;
            @include onlyMobile {
                font-size: 13px;
            }
            input[type="checkbox"] {
                &.is-error {
                    border: solid 1px $red;
                    background: $redbg
                }
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            p {
                text-align: center;
            }
        }
        &-submit {
            text-align: center;
            .u-button {
                margin: 0 5px;
                @include onlyMobile {
                    margin-bottom: 10px;
                }
            }
        }
    }
    &-error {
        padding: 30px;
        background: $redbg;
        color: $red;
        ul {
            li {
                position: relative;
                padding-left: 1em;
                &:before {
                    position: absolute;
                    top: 0.8em;
                    left: 0;
                    display: block;
                    content: "";
                    width: 3px;
                    height: 3px;
                    background: $red;
                    border-radius: 50%;
                }
            }
        }
    }
    &-confirm {
        .c-form-item-input {
            padding-top: 3px;
            @include onlyMobile {
                padding-top: 0;
            }
        }
    }
}

.c-list-news {
    margin-bottom: 60px;
    ul {
        li {
            border-bottom: solid 1px $graylight2;
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.5s $ease;
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            a {
                display: flex;
                padding: 15px;
                transition: all 0.3s $ease;
                @include onlyMobile {
                    flex-wrap: wrap;
                }
                &:hover {
                    background: $graylightbg;
                }
            }
        }
    }
    &-date {
        margin-right: 15px;
    }
    &-category {
        width: 130px;
        span {
            display: block;
            padding: 3px 15px;
            margin-right: 15px;
            text-align: center;
            font-size: 10px;
            background: $yellow;
            color: $white;
        }
    }
    &-title {
        flex: 1;
        @include onlyMobile {
            flex: none;
            width: 100%;
            margin-top: 5px;
        }
    }
}

.c-news-pickup {
    border: solid 1px $yellow;
    .c-list-news {
        margin-bottom: 0;
        ul {
            li {
                &:last-of-type {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}

.c-list-side-normal {
    ul {
        border-top: solid 1px $graylight2;
        li {
            border-bottom: solid 1px $graylight2;
            a {
                display: block;
                padding: 10px;
                font-size: 12px;
                transition: all 0.3s $ease;
                &:hover {
                    background: $graylightbg;
                }
            }
        }
    }
    &-date {
        font-size: 10px;
    }
}

.c-faq-tabs {
    &-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding: 10px;
        background: linear-gradient(135deg, $yellowlight2, $yellow);
        @include onlyMobile {
            padding: 5px;
        }
    }
    flex: 1;
    display: flex;
    margin-right: 10px;
    @include onlyMobile {
        margin-right: 5px;
    }
    li {
        flex: 1;
        margin-right: 10px;
        @include onlyMobile {
            margin-right: 5px;
        }
        a {
            position: relative;
            display: block;
            padding: 15px;
            text-align: center;
            background: $black;
            color: $white;
            cursor: pointer;
            transition: all 0.3s $ease;
            @include onlyMobile {
                padding: 10px 5px;
                font-size: 10px;
            }
            &:before {
                position: absolute;
                top: 3px;
                left: 3px;
                display: block;
                content: "";
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                border: 1px solid rgba(255,255,255,0.5);
                transition: all 0.5s $ease;
            }
            &:hover {
                background: $blackhover;
                &:before {
                    top: 5px;
                    left: 5px;
                    width: calc(100% - 12px);
                    height: calc(100% - 12px);
                    border: 1px solid rgba(255,255,255,1);
                }
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
        &.is-active {
            a {
                background: $white;
                color: $black;
                font-weight: 700;
            }
        }
    }
    &-category {
        display: flex;
        width: 57px;
        background: none;
        @include onlyMobile {
            width: 47px;
        }
        &:before {
            display: none;
        }
        .u-button {
            width: 57px;
            height: 100%;
            padding: 15px 10px;
            @include onlyMobile {
                width: 47px;
                height: auto;
                padding: 10px 10px;
            }
            &:after {
                display: none;
            }
            i {
                margin-right: 0;
            }
        }
    }
    &-nomargin {
        margin-right: 0;
    }
}

.c-faq-tabs-contents {
    &-item {
        &-search {
            display: flex;
            align-items: center;
            margin-bottom: 60px;
            padding: 30px;
            border: solid 1px $yellow;
            @include onlyMobile {
                margin-bottom: 30px;
                padding: 15px;
            }
            form {
                position: relative;
                flex: 1;
                margin-right: 20px;
                @include onlyMobile {
                    margin-right: 15px;
                }
                input[type="text"] {
                    display: block;
                    width: 100%;
                    padding: 14px 50px 14px 15px;
                    border: 1px solid $yellow;
                    background: $graylightbg;
                    transition: all 0.5s $ease;
                    @include onlyMobile {
                        padding: 10px 50px 10px 15px;
                    }
                    &:focus {
                        border: 1px solid $yellowlight;
                        background: $white;
                    }
                }
                button {
                    position: absolute;
                    top: 7px;
                    right: 5px;
                    border: none;
                    background: none;
                    font-size: 30px;
                    cursor: pointer;
                    transition: all 0.5s $ease;
                    @include onlyMobile {
                        top: 3px;
                    }
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

.c-accordion{
    &-trigger{
        position: relative;
        display: block;
        padding: 15px 50px 15px 15px;
        border: none;
        background: none;
        font-size: 17px;
        line-height: 1.5;
        cursor: pointer;
        transition: all 0.5s $ease;
        @include onlyMobile {
            padding: 15px 35px 15px 15px;
            font-size: 15px;
        }
        &:before {
            position: absolute;
            top: 50%;
            right: 10px;
            display: block;
            content: "";
            width: 20px;
            height: 1px;
            background: $yellow;
            transition: all 0.5s $ease;
        }
        &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            display: block;
            content: "";
            width: 20px;
            height: 1px;
            background: $yellow;
            transform: rotate(90deg);
            transition: all 0.5s $ease;
        }
        &:hover {
            background: $graylightbg;
        }
        &.is-open {
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                position: absolute;
                top: 50%;
                right: 10px;
                display: block;
                content: "";
                width: 20px;
                height: 1px;
                background: $yellow;
                transform: rotate(135deg);
            }
        }
    }
    &-target{
      transition: height 0.3s ease-in-out;
      overflow: hidden;
    }
    &-body{
        padding: 15px 15px 15px 35px;
        border-top: solid 1px $yellow;
        font-size: 13px;
        background: $graylightbg;
        p {
            margin-bottom: 10px;
            line-height: 1.5;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &-enter-active{
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: js-accordion--anime__opend;
    }
    &-leave-active{
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: js-accordion--anime__closed;
    }
}
  
@keyframes js-accordion--anime__opend {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
     }
}
@keyframes js-accordion--anime__closed {
    0% {
     opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
}

.c-list-faq {
    margin-bottom: 30px;
    &-category {
        margin-bottom: 10px;
        font-size: 23px;
        @include onlyMobile {
            font-size: 17px;
        }
    }
    ul {
        border: solid 1px $yellow;
        li {
            border-bottom: solid 1px $yellow;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

.c-local-navi {
    padding: 10px;
    margin-bottom: 60px;
    background: linear-gradient(135deg, $yellowlight2, $yellow);
    @include onlyMobile {
        margin-bottom: 30px;
    }
    ul {
        display: flex;
        @include onlyMobile {
            flex-wrap: wrap;
            margin-bottom: -5px;
        }
        li {
            flex: 1;
            display: flex;
            margin-right: 10px;
            @include onlyMobile {
                flex: none;
                width: 48.5%;
                margin: 0 3% 5px 0;
            }
            &:nth-child(2n) {
                @include onlyMobile {
                    margin-right: 0;
                }
            }
            &:last-of-type {
                margin-right: 0;
            }
            .u-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 15px 10px;
                font-size: 13px;
                &:after {
                    display: none;
                }
            }
            &.is-active {
                .u-button {
                    background: $white;
                    color: $black;
                    font-weight: 700;
                }
            }
        }
    }
}

.c-dl-normal {
    &-item {
        display: flex;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: solid 1px $graylight2;
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        dt {
            width: 200px;
            text-align: right;
            font-weight: 700;
        }
        dd {
            flex: 1;
            padding-left: 30px;
        }
    }
}

.c-sitemap {
    display: flex;
    justify-content: space-between;
    @include onlyMobile {
        flex-direction: column;
    }
    &-item {
        width: 30%;
        border-right: solid 1px $yellow;
        padding-right: 3.33333333%;
        @include onlyMobile {
            width: 100%;
            border-right: none;
            border-bottom: solid 1px $yellow;
            padding-right: 0;
            margin-bottom: 30px;
        }
        &:last-of-type {
            border-right: none;
            @include onlyMobile {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
        > ul {
            > li {
                margin-bottom: 10px;
                > a {
                    position: relative;
                    display: block;
                    padding-left: 15px;
                    font-size:  17px;
                    transition: all 0.5s $ease;
                    &:before {
                        position: absolute;
                        top: 0.8em;
                        left: 0;
                        display: block;
                        content: "";
                        width: 5px;
                        height: 5px;
                        border-top: solid 1px $yellow;
                        border-right: solid 1px $yellow;
                        transform: rotate(45deg);
                        transition: all 0.5s $ease;
                    }
                    &:hover {
                        text-decoration: underline;
                        &:before {
                            left: 5px;
                        }
                    }
                }
                > ul {
                    margin-top: 5px;
                    margin-bottom: 30px;
                    padding-left: 20px;
                    > li {
                        margin-bottom: 5px;
                        > a {
                            position: relative;
                            display: block;
                            padding-left: 15px;
                            font-size: 13px;
                            &:before {
                                position: absolute;
                                top: 0.75em;
                                left: 0;
                                display: block;
                                content: "";
                                width: 3px;
                                height: 3px;
                                border-top: solid 1px $yellow;
                                border-right: solid 1px $yellow;
                                transform: rotate(45deg);
                                transition: all 0.5s $ease;
                            }
                            &:hover {
                                text-decoration: underline;
                                &:before {
                                    left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.c-hero-original {
    overflow: hidden;
    &-title {
        padding: 30px;
        background: linear-gradient(135deg, $yellowlight2, $yellow);
        text-align: center;
        color: $white;
        line-height: 1.5;
        font-size: 25px;
        @include onlyMobile {
            padding: 20px;
            font-size: 20px;
        }
        span {
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
        small {
            display: block;
            font-size: 15px;
            @include onlyMobile {
                font-size: 12px;
            }
        }
    }
    &-images {
        position: relative;
        display: flex;
        &-main {
            width: 50%;
            &.is-ready {
                opacity: 0;
                transform: translateX(-10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-sub {
            width: 50%;
            &.is-ready {
                opacity: 0;
                transform: translateX(10px);
            }
            &.animated {
                opacity: 1;
                transform: translateX(0);
            }
            &-top {
                &.is-ready {
                    opacity: 0;
                    transform: translateX(10px);
                }
                &.animated {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
            &-buttom {
                display: flex;
                &-item {
                    width: 50%;
                    &.is-ready {
                        opacity: 0;
                        transform: translateX(10px);
                    }
                    &.animated {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
        }
        &-logo {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 11%;
            @include onlyMobile {
                top: 5px;
                right: 5px;
                width: 20%;
            }
            img {
                width: 100%;
                height: auto;
            }
        }  
    }
}

.c-original-message {
    position: relative;
    margin-top: -60px;
    padding: 0 30px;
    z-index: 10;
    @include onlyMobile {
        margin-top: -30px;
    }
    &-inner {
        display: flex;
        align-items: center;
        max-width: 1000px;
        margin: 0 auto;
        background: $white;
        box-shadow: 0 0 20px -5px rgba(0,0,0,0.12);
        @include onlyMobile {
            flex-direction: column;
        }
    }
    &-image {
        width: 25%;
        @include onlyMobile {
            width: 100%;
        }
    }
    &-detail {
        flex: 1;
        padding: 30px;
        font-size: 17px;
        @include onlyMobile {
            padding: 20px;
            font-size: 13px;
        }
    }
}

.c-contact-column {
    display: flex;
    justify-content: space-between;
    @include onlyMobile {
        flex-direction: column;
    }
    &-item {
        width: 48%;
        @include onlyMobile {
            width: 100%;
        }
        &:first-of-type {
            @include onlyMobile {
                margin-bottom: 30px;
                padding-bottom: 30px;
                border-bottom: solid 1px $graylight;
            }
        }
        .u-button {
            width: 100%;
        }
        &-tel {
            text-align: center;
            span {
                display: inline-block;
                padding: 10px 20px;
                background: $graylight;
                i {
                    position: relative;
                    top: 2px;
                    font-size: 20px;
                }
                small {
                    font-size: 10px;
                }
            }
        }
    }
    .logo {
        max-width: 200px;
        margin: 0 auto;
    }
}

.c-hero-rental-images {
    position: relative;
    width: 70%;
    height: 640px;
    @include onlyMobile {
        width: 100%;
        height: auto;
    }
    &-wrap {
        display: flex;
        @include onlyMobile {
            flex-direction: column;
        }
        .u-button {
            position: relative;
            width: 30%;
            font-size: 1.3vw;
            line-height: 1.5;
            background: url(/assets/img/bg-button-rental.jpg) no-repeat center center;
            background-size: cover;
            @include onlyMobile {
                width: 100%;
                height: 300px;
                font-size: 5vw;
            }
            i {
                margin-bottom: 1vw;
                font-size: 6vw;
                @include onlyMobile {
                    font-size: 15vw;
                }
            }
            &:before {
                z-index: 10;
            }
            &:after {
                display: none;
            }
            &-inner {
                position: relative;
                z-index: 10;
            }
            .u-button-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                content: "";
                background: rgba(0,0,0,0.5);
                z-index: 0;
                transition: all 0.3s $ease;
            }
            &:hover {
                background: url(/assets/img/bg-button-rental.jpg) no-repeat center center;
                background-size: cover;
                .u-button-overlay {
                    background: rgba(0,0,0,0);
                }
            }
            &.is-disabled {
                pointer-events: none;
                cursor: default;
                .u-button-overlay {
                    display: none;
                }
                .u-button-inner {
                    display: none;
                }
                &:before {
                    display: none;
                }
            }
        }
    }
    &-top {
        display: flex !important;
        height: 55%;
        @include onlyMobile {
            flex-wrap: wrap;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(-10px);
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
        &-item {
            width: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-bottom {
        display: flex !important;
        height: 45%;
        @include onlyMobile {
            flex-wrap: wrap;
        }
        &.is-ready {
            opacity: 0;
            transform: translateX(10px);
        }
        &.animated {
            opacity: 1;
            transform: translateX(0);
        }
        &-item {
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &-01 {
                width: 30%;
                @include onlyMobile {
                    width: 50%;
                }
            }
            &-02 {
                width: 15%;
                @include onlyMobile {
                    width: 25%;
                }
            }
            &-03 {
                width: 15%;
                @include onlyMobile {
                    width: 25%;
                }
            }
            &-04 {
                width: 20%;
                @include onlyMobile {
                    width: 50%;
                }
            }
            &-05 {
                width: 20%;
                @include onlyMobile {
                    width: 50%;
                }
            }
        }
    }
}

.c-ranking-tabs {
    &-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding: 10px;
        background: linear-gradient(135deg, $yellowlight2, $yellow);
        @include onlyMobile {
            padding: 5px;
        }
    }
    flex: 1;
    display: flex;
    margin-right: 10px;
    @include onlyMobile {
        margin-right: 5px;
    }
    li {
        position: relative;
        flex: 1;
        padding: 15px;
        margin-right: 10px;
        text-align: center;
        background: $black;
        color: $white;
        cursor: pointer;
        transition: all 0.3s $ease;
        @include onlyMobile {
            margin-right: 5px;
            padding: 10px 10px;
            font-size: 12px;
        }
        &:before {
            position: absolute;
            top: 3px;
            left: 3px;
            display: block;
            content: "";
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            border: 1px solid rgba(255,255,255,0.5);
            transition: all 0.5s $ease;
        }
        &:hover {
            background: $blackhover;
            &:before {
                top: 5px;
                left: 5px;
                width: calc(100% - 12px);
                height: calc(100% - 12px);
                border: 1px solid rgba(255,255,255,1);
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
        &.is-active {
            background: $white;
            color: $black;
            font-weight: 700;
        }
    }
}

.c-list-ranking {
    ul {
        counter-reset: number 0;
        li {
            position: relative;
            .c-list-ranking-number {
                position: absolute;
                top: -5px;
                left: -5px;
                display: block;
                width: 35px;
                height: 35px;
                background: $black;
                z-index: 10;
                color: $white;
                font-size: 18px;
                text-align: center;
                line-height: 35px;
                transform: rotate(45deg);
                &:before {
                    display: block;
                    counter-increment: number 1;
                    content: counter(number) " ";
                    transform: rotate(-45deg);
                }
            }
            &:nth-child(1) {
                .c-list-ranking-number {
                    background: $yellow;
                }
            }
            &:nth-child(2) {
                .c-list-ranking-number {
                    background: $graylight;
                    &:before {
                        color: $black;
                    }
                }
            }
            &:nth-child(3) {
                .c-list-ranking-number {
                    background: #f99224;
                }
            }
        }
    }
}

.c-list-faq-ranking {
    ul {
        counter-reset: number 0;
        li {
            border-bottom: solid 1px $graylight;
            a {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 15px 0;
                transition: all 0.3s $ease;
                &:hover {
                    background: $graylightbg;
                }
                .c-list-ranking-number {
                    display: block;
                    width: 35px;
                    height: 35px;
                    margin-right: 20px;
                    background: $black;
                    z-index: 10;
                    color: $white;
                    font-size: 18px;
                    text-align: center;
                    line-height: 35px;
                    transform: rotate(45deg);
                    &:before {
                        display: block;
                        counter-increment: number 1;
                        content: counter(number) " ";
                        transform: rotate(-45deg);
                    }
                }
            }
            &:nth-child(1) {
                .c-list-ranking-number {
                    background: $yellow;
                }
            }
            &:nth-child(2) {
                .c-list-ranking-number {
                    background: $graylight;
                    &:before {
                        color: $black;
                    }
                }
            }
            &:nth-child(3) {
                .c-list-ranking-number {
                    background: #f99224;
                }
            }
        }
    }
}

.c-box-flow-form {
    &-list {
        ul {
            display: flex;
            @include onlyMobile {
                flex-direction: column;
            }
            li {
                position: relative;
                width: 24%;
                display: flex !important;
                align-items: center;
                justify-content: center;
                margin-right: 4%;
                padding: 30px 15px;
                background: $graylightbg;
                text-align: center;
                font-size: 17px;
                @include onlyMobile {
                    width: 100%;
                    padding: 15px;
                    margin: 0 0 30px 0;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -15px;
                    border: 15px solid transparent;
                    border-left: 15px solid $graylightbg;
                    @include onlyMobile {
                        top: 100%;
                        left: 50%;
                        margin-top: 0;
                        margin-left: -15px;
                        transform: rotate(90deg);
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                    @include onlyMobile {
                        margin-bottom: 0;
                    }
                    &::before {
                        display: none;
                    }
                }
                &:nth-child(1) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease;  
                }
                &:nth-child(2) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease 0.1s;  
                    @include onlyMobile {
                        transition: all 0.5s $ease; 
                    }
                }
                &:nth-child(3) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease 0.2s;  
                    @include onlyMobile {
                        transition: all 0.5s $ease; 
                    }
                }
                &:nth-child(4) {
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all 0.5s $ease 0.3s; 
                    @include onlyMobile {
                        transition: all 0.5s $ease; 
                    }
                }
                &.is-active {
                    background: $yellow;
                    color: $white;
                    &:before {
                        border-left-color: $yellow;
                        @include onlyMobile {
                            top: 100%;
                            left: 50%;
                            margin-top: 0;
                            margin-left: -15px;
                            transform: rotate(90deg);
                        }
                    }

                }
                &.animated {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
        &-number {
            position: absolute;
            top: -10px;
            left: -10px;
            display: block;
            width: 30px;
            height: 30px;
            font-size: 17px;
            line-height: 30px;
            text-align: center;
            color: $yellow;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 30px;
                height: 30px;
                background: $white;
                transform: rotate(45deg);
            }
            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
                display: block;
                content: "";
                width: 25px;
                height: 25px;
                border: solid 1px $yellow;
                transform: rotate(45deg);
            }
            span {
                position: relative;
            }
        }
    }
}

.c-box-payment {
    display: flex;
    justify-content: center;
    padding: 15px;
    background: $graylightbg;
    font-size: 17px;
    &-title {
        margin-right: 20px;
    }
    &-date {
        font-weight: 700;
    }
}

.c-dl-list {
    &-item {
        display: flex;
        margin-bottom: 10px;
        @include onlyMobile {
            flex-direction: column;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        dt {
            width: 200px;
            text-align: right;
            @include onlyMobile {
                width: 100%;
                margin-bottom: 5px;
                text-align: left;
            }
        }
        dd {
            flex: 1;
            padding-left: 20px;
            font-weight: bold;
            @include onlyMobile {
                padding-left: 0;
            }
        }
    }
}

.c-arrow-up {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: -30px;
        left: 50%;
        margin-left: -15px;
        border: 15px solid transparent;
        border-bottom: 15px solid $graylightbg;
    }
}

.c-faq-categories {
    button {
        font-size: 17px;
        border: none;
        background: none;
        cursor: pointer;
        transition: all 0.5s $ease;
        &:hover {
            opacity: 0.7;
        }
        i { 
            position: relative;
            top: 2px;
            font-size: 22px;
        }
    }
}

.c-youtube-embed {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    padding-bottom: 52.25%;
    overflow: hidden;
    background: linear-gradient(135deg, $yellowlight2, $yellow);
    iframe {
        width: calc( 100% - 20px );
        height: calc( 100% - 20px );
        position: absolute;
        top: 10px;
        left: 10px;
    }
}

.c-user-voice {
    max-width: 800px;
    margin: 0 auto;
    &-item {
        &-voice {
            display: flex;
            padding-right: 30px;
            &-icon {
                width: 80px;
                margin-right: 30px;
                span {
                    display: block;
                    width: 80px;
                    height: 80px;
                    padding: 20px;
                    border-radius: 50%;
                    background: $yellow;
                }
            }
            &-detail {
                position: relative;
                flex: 1;
                display: flex;
                align-items: center;
                padding: 15px 30px;
                background: $yellow;
                color: #fff;
                font-size: 17px;
                font-weight: 700;
                line-height: 1.5;
                @include onlyMobile {
                    padding: 15px;
                    font-size: 15px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -20px;
                    margin-top: -10px;
                    border: 10px solid transparent;
                    border-right: 10px solid $yellow;
                    @include onlyMobile {
                        top: 40px;
                    }
                }
            }
            &-reverse {
                flex-direction: row-reverse;
                padding-right: 0;
                padding-left: 30px;
                .c-user-voice-item-voice-icon {
                    margin-right: 0;
                    margin-left: 30px;
                }
                .c-user-voice-item-voice-detail {
                    &:before {
                        left: inherit;
                        right: -20px;
                        transform: rotate(180deg);
                        @include onlyMobile {
                            top: 40px;
                        }
                    }
                }
                .c-user-voice-item-voice-icon {
                    span {
                        padding: 15px;
                    }
                }
            }
            &-result {
                display: flex;
                align-items: center;
                padding: 30px;
                border: solid 1px $white;
                line-height: 1.5;
                @include onlyMobile {
                    flex-direction: column;
                    padding: 15px;
                }
                &-title {
                    width: 100px;
                    padding-right: 20px;
                    margin-right: 30px;
                    border-right: solid 1px $white;
                    @include onlyMobile {
                        width: 100%;
                        padding-right: 0;
                        padding-bottom: 15px;
                        margin-right: 0;
                        margin-bottom: 15px;
                        border-right: none;
                        border-bottom: solid 1px $white;
                        text-align: center !important;
                    }
                }
                &-body {
                    line-height: 1.5;
                }
            }
        }
    }
}

.c-box-bordertop {
    padding-top: 30px;
    border-top: solid 1px $yellow;
}

.c-list-image {
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            position: relative;
            width: 31%;
            margin-right: 3.5%;
            &:nth-child(3n) {
                margin-right: 0;
            }
            button {
                i {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    color: $white;
                }
                &:hover {
                    i {
                        opacity: 0.5;
                        color: $white;
                    }
                }
            }
        }
    }
}

.c-side-search {
    display: flex;
    align-items: center;
    @include onlyMobile {
        margin-bottom: 30px;
        padding: 15px;
    }
    form {
        position: relative;
        flex: 1;
        @include onlyMobile {
            margin-right: 15px;
        }
        input[type="text"] {
            display: block;
            width: 100%;
            padding: 14px 50px 14px 15px;
            border: 1px solid $yellow;
            background: $graylightbg;
            transition: all 0.5s $ease;
            @include onlyMobile {
                padding: 10px 50px 10px 15px;
            }
            &:focus {
                border: 1px solid $yellowlight;
                background: $white;
            }
        }
        button {
            position: absolute;
            top: 7px;
            right: 5px;
            border: none;
            background: none;
            font-size: 30px;
            cursor: pointer;
            transition: all 0.5s $ease;
            @include onlyMobile {
                top: 3px;
            }
            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.c-article-banner {
    margin-bottom: 30px;
    img {
        border: solid 1px $yellow;
    }
}

.c-list-faq-article {
    margin-bottom: 30px;
    &-title {
        padding: 7px 15px;
        background: $yellow;
        color: $white;
        font-size: 20px;
    }
    ul {
        padding: 15px 15px;
        border: solid 1px $yellow;
        background: $graylightbg;
        li {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px $graylight2;
            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            a {
                position: relative;
                display: block;
                padding-left: 20px;
                transition: all 0.5s $ease;
                color: $bluelight2;
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    display: block;
                    content: "";
                    width: 7px;
                    height: 7px;
                    border-top: solid 1px $yellow;
                    border-right: solid 1px $yellow;
                    transform: rotate(45deg);
                }
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

.c-list-relation {
    margin-bottom: 30px;
    &-title {
        margin-bottom: 0 !important;
        padding: 7px 15px;
        background: $yellow;
        color: $white;
        font-size: 20px;
    }
    ul {
        padding: 15px 15px;
        border: solid 1px $yellow;
        background: $graylightbg;
        li {
            margin-bottom: 10px;
            padding-bottom: 10px;
            padding-left: 0 !important;
            border-bottom: solid 1px $graylight2;
            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            &:before {
                display: none;
            }
            a {
                position: relative;
                display: block;
                padding-left: 20px;
                transition: all 0.5s $ease;
                color: $bluelight2;
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    display: block;
                    content: "";
                    width: 7px;
                    height: 7px;
                    border-top: solid 1px $yellow;
                    border-right: solid 1px $yellow;
                    transform: rotate(45deg);
                }
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

.c-entry-box-border {
    padding: 15px;
    margin-bottom: 30px;
    border: solid 1px $gray;
    &-red {
        background: $redbg;
    }
}

.c-campaign-main {
    position: relative;
    &-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &-title {
        margin-bottom: 30px;
        font-size: 32px;
        font-weight: 700;
    }
    &-lead {
        font-size: 17px;
    }
}

.c-campaign-main-2022 {
    position: relative;
    height: calc( 100vh - 105px );
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4% 60px;
    @include onlyMobile {
        padding: 30px 15px 15px 15px;
        height: 110vh;
        flex-direction: column;
        justify-content: space-between;
    }
    img {
        position: absolute;
        bottom: 0;
        left: 8%;
        width: auto;
        height: 93vh;
        @include onlyMobile {
            left: 0;
            width: 100%;
            height: 92%;
            object-fit: cover;
        }
    }
    &-body {
        position: relative;
        width: 62%;
        text-align: center;
        @include onlyMobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
        &-head {
            margin-bottom: 15px;
            @include onlyMobile {
                margin-bottom: auto;
            }
        }
        .u-button {
            font-size: 13px;
        }
    }
    &-caption {
        @include onlyMobile {
            font-size: 3vw;
        }
    }
    &-title {
        font-size: 54px;
        line-height: 1.3;
        @include onlyMobile {
            font-size: 10.5vw;
        }
    }
    &-lead {
        margin-bottom: 15px;
        font-size: 30px;
        @include onlyMobile {
            font-size: 4vw;
        }
    }
    &-text {
        margin-bottom: 30px;
        line-height: 1.5;
        @include onlyMobile {
            font-size: 3vw;
        }
        strong {
            color: #d9073d;
        }
        p {
            margin-bottom: 15px;
            text-align: center;
        }
    }
    &-detail {
        @include onlyMobile {
            padding: 3vw;
            margin-top: auto;
            background: rgba(255,255,255,0.7);
        }
    }
}

.c-antoniotarontino-image {
    a {
        &:hover {
            cursor: pointer;
        }
    }
    .is-sp {
        position: relative;
        .image01 {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 45%;
            height: 50%;
        }
        .image02 {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 45%;
            height: 50%;
        }
    }
}


.c-price-table {
  margin: 0 auto;  
  font-weight: bold;
  @include onlyMobile {
    font-size: 10px;
  }
  &-head {
    display: flex;
    padding: 0 20px 0 0;
    text-align: center;
    @include onlyMobile {
      padding: 0 10px 0 0;
    }
    &-col1 {
      width: 30%;
      padding: 15px;
      @include onlyMobile {
        padding: 10px;
      }
    }
    &-col2 {
      width: 30%;
      padding: 15px;
      font-weight: bold;
      @include onlyMobile {
        padding: 10px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
    &-col3 {
      width: 40%;
      padding: 15px;
      font-size: 1.3em;
      font-weight: bold;
      background: $black;
      color: $white;
      border-bottom: solid 1px $graylight;
      border-top: solid 3px $black;
      border-left: solid 3px $black;
      border-right: solid 3px $black;
      @include onlyMobile {
        font-size: 1em;
        padding: 10px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
  &-body {
    padding: 0 20px 0 0;
    background: linear-gradient(135deg, $yellowlight2, $yellow);
    @include onlyMobile {
      padding: 0 10px 0 0;
    }
    &-row {
      display: flex;
      text-align: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-weight: bold;
        
        &.stroke {
          .inner {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            
            &:before,
            &:after {
              position: absolute;
              content: "";
              display: block;
              transform: rotate(0);
              background-color: #000;
              width: 100%;
              height: 2px;
              left: 0;
            }
            &:before {
              top: 55%;
            }
            &:after {
              top: 42%;
            }
          }
          
        }
      }
      &:last-of-type {
        .c-price-table-body-col3 {
          &:before {
            position: absolute;
            bottom: -10px;
            left: -3px;
            display: block;
            content: "";
            width: 100%;
            height: 10px;
            background: $white;
            border-left: solid 3px $black;
            border-right: solid 3px $black;
            border-bottom: solid 3px $black;
          }
        }
      }
    }
    &-col1 {
      width: 30%;
      padding: 15px;
      font-weight: bold;
      border-right: solid 1px rgba(255,255,255,0.3);
      border-bottom: solid 1px rgba(255,255,255,0.3);
      @include onlyMobile {
        padding: 10px;
      }
    }
    &-col2 {
      width: 30%;
      padding: 15px;
      color: $white;
      border-bottom: solid 1px rgba(255,255,255,0.3);
      @include onlyMobile {
        padding: 10px;
      }
    }
    &-col3 {
      position: relative;
      width: 40%;
      padding: 15px;
      background: $white;
      border-bottom: solid 1px $graylight;
      border-left: solid 3px $black;
      border-right: solid 3px $black;
      @include onlyMobile {
        padding: 10px;
      }
      strong {
        color: $red;
        font-size: 1.2em;
      }
      span {
        @include onlyMobile {
          display: inline-block;
        }
      }
    }
  }
}

.c-table-guarantee {
  width: 60%;
  min-width: 480px;
  margin: 0 auto;
  border-top: solid 1px $yellow;
  border-left: solid 1px $yellow;
  
  @include onlyMobile {
    width: 100%;
    min-width: inherit;
  }
  
  thead {
    th {
      width: 50%;
      padding: 15px;
      text-align: center;
      background: $yellow;
      color: $white;
      border-right: solid 1px $yellow;
      
      &:nth-child(1) {
        border-right: solid 1px $white;
      }
    }
  }
  
  tbody {
    th {
      padding: 15px;
      text-align: center;
      border-right: solid 1px $yellow;
      border-bottom: solid 1px $yellow;
    }
    
    td {
      padding: 15px;
      text-align: center;
      border-right: solid 1px $yellow;
      border-bottom: solid 1px $yellow;
    }
  }
  
  
}