@mixin onlyMobile {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}



@mixin onlySmallDesktop {
  @media only screen and (max-width: 1180px) {
    @content;
  }
}

@mixin onlyDesktop {
  @media only screen and (min-width: 1181px) {
    @content;
  }
}

@mixin onlyLargeDesktop {
  @media only screen and (min-width: 1680x) {
    @content;
  }
}

@mixin fontFamily($fontName) {
  font-family: $fontName, sans-serif;
}

@mixin fix() {
  &:after {
    display: block;
    content: '';
    visibility: hidden;
    clear: both;
    height: 0;
  }
}

// * hack IE
$hack-ie11: "*::-ms-backdrop";
@mixin hack-ie($hack-ver:'') {
    // * IE8 hack
    @if $hack-ver == "ie8" {
        @media \0screen {
            @content;
        }
    }
    // * IE9-10 hack(add propary \9)
    @else if $hack-ver == "ie9-10" {
        @media all and (min-width:0\0) {
            @content;
        }
    }
    // * IE10 hack(add propary \9)
    @else if $hack-ver == "ie10" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
    // * IE11 hack(add selector *::-ms-backdrop)
    @else if $hack-ver == "ie11" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
}
