/*
.is-ready {
  opacity: 0;
  transition: all .5s $ease;
}

.is-ready.animated {
  opacity: 1;
  transform: translate(0);
}
*/

.is-pc {
  @include onlyMobile {
    display: none;
  }
}

.is-mobile {
  display: none;
  @include onlyMobile {
    display: block;
  }
}

.u-list-number {
  padding-left: 1em;
  list-style: decimal;
  list-style-position: outside;
  li {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.u-list-icon {
  li {
    position: relative;
    padding-left: 1em;
    margin-bottom: 10px;
    line-height: 1.5;
    &:before {
      position: absolute;
      top: 0.7em;
      left: 0;
      display: block;
      content: "";
      width: 5px;
      height: 5px;
      background: $yellow;
      border-radius: 50%;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.u-button {
  position: relative;
  display: inline-block;
  padding: 12px 25px;
  background: $yellow;
  color: $white;
  text-align: center;
  line-height: 1.3;
  border: none;
  cursor: pointer;
  transition: all 0.3s $ease;
  font-family: "Noto Serif JP", "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  &:before {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 8px);
    height:  calc(100% - 8px);
    display: block;
    content: "";
    border: solid 1px rgba(255,255,255,0.5);
    transition: all 0.3s $ease;
  }
  &:after {
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    content: "";
    width: 5px;
    height: 5px;
    margin-top: -2.5px;
    border-top: solid 1px rgba(255,255,255,0.5);
    border-right: solid 1px rgba(255,255,255,0.5);
    transform: rotate(45deg);
    transition: all 0.3s $ease;
  }
  &:hover {
    background: $yellowhover;
    &:before {
      top: 5px;
      left: 5px;
      width: calc(100% - 12px);
      height:  calc(100% - 12px);
      border: solid 1px rgba(255,255,255,1);
    }
    &:after {
      position: absolute;
      right: 13px;
      border-top: solid 1px rgba(255,255,255,1);
      border-right: solid 1px rgba(255,255,255,1);
    }
  }
  i {
    position: relative;
    top: 0.05em;
    margin-right: 5px;
    font-size: 1.6em;
    + span {
      display: inline-block;
      position: relative;
      top: -0.1em;
    }
  }
  &-large {
    min-width: 300px;
    padding: 17px 35px;
    font-size: 17px;
    font-weight: bold;
    @include onlyMobile {
      width: 100%;
      min-width: inherit;
    }
  }
  &-fullwidth {
    width: 100%;
  }
  &-secondary {
    background: $black;
    &:hover {
      background: $blackhover;
    }
  }
  &-gray {
    background: linear-gradient(135deg, $blackhover3, $blackhover2);
    color: $white;
    &:before {
      border-color: $graylight2;
    }
    &:hover {
      background: linear-gradient(135deg, $blackhover3, $black);
    }
  }
  &-pink {
    background: $rosso;
    color: $white;
    transition: all 0.3s $ease;
    &:before {
      border-color: $white;
    }
    &:after {
      border-top: 1px solid $white;
      border-right: 1px solid $white;
    }
    &:hover {
      background: $rossolight;
    }
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      position: relative;
      top: 0;
      font-size: 27px;
    }
    &-inline {
      display: inline-flex;
      i {
        position: relative;
        top: 0;
        font-size: 27px;
      }
    }
  }
}

.sans {
  .u-button {
    font-family: "Noto Sans JP", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  }
}

.u-table {
  width: 100%;
  color: $black;
  text-align: center;
  line-height: 1.5;
  @include onlyMobile {
    font-size: 13px;
  }
  &-small {
    font-size: 13px;
  }
  &-sans {
    font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  }
  &-wrap {
    width: 100%;
    overflow-x: scroll;
  }
  &-col4 {
    width: 11.111111111%;
  }
  &-col4-2 {
    width: 4%;
  }
  &-col10 {
    width: 10%;
  }
  &-col11 {
    width: 11.111111111%;
  }
  &-col13 {
    width: 13%;
  }
  &-col14 {
    width: 14%;
  }
  &-col16 {
    width: 16.666666665%;
  }
  &-col17-2 {
    width: 17%;
  }
  &-col17 {
    width: 17.5%;
  }
  &-col18 {
    width: 18%;
  }
  &-col20 {
    width: 20%;
  }
  &-col25 {
    width: 25%;
  }
  &-col30 {
    width: 30%;
  }
  &-col33 {
    width: 33.33333333%;
  }
  &-col34 {
    width: 34%;
  }
  &-col36 {
    width: 36%;
  }
  &-col40 {
    width: 40%;
  }
  &-col70 {
    width: 70%;
  }
  tr {
    th {
      padding: 15px;
      text-align: center;
      border-right: solid 1px $graylight2;
      border-bottom: solid 1px $graylight2;
      background: $black;
      color: $white;
      line-height: 1.5;
    }
    td {
      padding: 10px 15px;
      text-align: center;
      border-right: solid 1px $graylight2;
      border-bottom: solid 1px $graylight2;
      background: $white;
      line-height: 1.5;
      &:first-of-type {
        border-left: solid 1px $graylight2;
      }
      p{
        line-height: 1.5;
        small {
          display: block;
        }
      }
      .u-align-left {
        text-align: justify !important;
        text-justify: inter-ideograph !important;
      }
    }
  }
  &-yellow {
    background: $yellowlight5 !important;
  }
  &-gray {
    background: $graylightbg !important;
  }
  &-price {
    background: $graylightbg;
    font-size: 17px;
    font-weight: 700;
    color: $yellow;
    small {
      font-size: 12px;
      font-weight: 400;
      color: $black;
    }
  }
  &-subtitle {
    background: $blacklight !important;
    color: $white;
    font-weight: 700;
  }
  &-title {
    background: $blacklight !important;
    font-weight: 700;
    color: $white;
  }
  &-empty {
    background: transparent !important;
    border-right: none !important;
    border-bottom: none !important;
  }
}

.u-box-border {
  padding: 30px;
  border: solid 1px $yellow;
  @include onlyMobile {
    padding: 15px;
  }
  &-white {
    padding: 30px;
    border: solid 1px $white;
    @include onlyMobile {
      padding: 15px;
    }
  }
}

.u-box-gray {
  padding: 30px;
  background: $graylightbg;
}

.u-box-white {
  padding: 15px;
  background: $white;
}

.u-img-140 {
  max-width: 140px;
}

.u-img-260 {
  max-width: 260px;
}

.u-img-280 {
  max-width: 280px;
}


.u-width-max-middle {
  max-width: 800px;
  margin: 0 auto;
}

.u-align-left {
  text-align: left !important;
}

.u-align-right {
  text-align: right !important;
}

.u-align-center {
  text-align: center !important;
}

.u-text-xlarge {
  font-size: 20px;
}

.u-text-large {
  font-size: 17px;
}

.u-text-middle {
  font-size: 15px;
}

.u-text-small {
  font-size: 12px;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-yellow {
  color: $yellowlight;
}

.u-link-border {
  text-decoration: underline;
}

.u-link-border:hover {
  text-decoration: none;
}

.u-text-red {
  color: #e64f4f;
}

.u-box-small {
  max-width: 800px;
  margin: 0 auto;
}

.u-mb-small {
  margin-bottom: 15px;
}

.u-mb {
  margin-bottom: 30px;
}

.u-mb-large {
  margin-bottom: 60px;
  @include onlyMobile {
    margin-bottom: 30px;
  }
}

.u-mb-small {
  margin-bottom: 15px;
}