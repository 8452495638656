.l-footer {
  background: $redlight;
  font-size: 12px;
  &-top {
    display: flex;
    width: 100%;
    @include onlyMobile {
      flex-direction: column;
    }
    &-title {
      width: 25%;
      @include onlyMobile {
        width: 100%;
      }
      .u-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: $black;
        padding: 18px 15px;
        &:hover {
          background: $blackhover;
        }
      }
    }
    &-navi {
      display: flex;
      width: 75%;
      @include onlyMobile {
        width: 100%;
      }
      ul {
        display: flex;
        width: 100%;
        @include onlyMobile {
          flex-direction: column;
        }
        li {
          display: flex;
          width: 33.3333%;
          @include onlyMobile {
            width: 100%;
          }
          .u-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 18px 15px;
            background: $gray;
            &:hover {
              background: $grayhover;
            }
          }
        }
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0 30px;
    margin-bottom: 15px;
    @include onlyMobile {
      flex-direction: column;
      padding: 15px 15px 0 15px;
    }
    a {
      color: $black;
      &:hover {
        color: $blackhover;
        text-decoration: underline;
      }
      i {
        position: relative;
        top: 0.05em;
        font-size: 17px;
      }
    }
    &-navi {
      @include onlyMobile {
        margin-bottom: 10px;
        text-align: center;
      }
      ul {
        display: flex;
        @include onlyMobile {
          display: block;
        }
        li {
          margin-right: 20px;
          @include onlyMobile {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    &-run {
      text-align: center;
    }
    &-sns {
      ul {
        display: flex;
        justify-content: flex-end;
        @include onlyMobile {
          justify-content: center;
        }
        li {
          margin-left: 5px;
          @include onlyMobile {
            margin: 0 2.5px;
          }
          a {
            transition: all 0.3s $ease;
            &:hover {
              opacity: 0.5;
            }
          }
          i {
            font-size: 30px;
          }
        }
      }
    }
  }
  &-information {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 30px;
    word-break: break-all;
    @include onlyMobile {
      flex-direction: column;
      padding: 0 15px 15px;
    }
  }
  &-jasrac {
    font-size: 10px;
    @include onlyMobile {
      margin-bottom: 10px;
    }
  }
  &-copyright {
    @include onlyMobile {
      text-align: center;
    }
  }
}