/** unmounting in html5 */
/**************************************/
address, article, aside, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

[hidden], command, datalist, menu[type=context], rp, source {
  display: none;
}

/** document */
/**************************************/
body {
  background: #fff;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
  text-align: left;
  margin: 0;
  padding: 0;

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

/** h1 h2 h3 h4 h5 */
/**************************************/
h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

/** ul ol dl */
/**************************************/
ul, ol, dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

dd {
  margin: 0;
}

/** p */
/**************************************/
p {
  line-height: 2;
  margin: 0;
  padding: 0;
}

/** tables */
/**************************************/
table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;

   > caption {
    text-align: left;
  }

  th {
    text-align: left;
    margin: 0;
    padding: 0;
  }

  td {
    margin: 0;
    padding: 0;
  }
}

/** form */
/**************************************/
form {
  margin: 0;
  padding: 0;
}

input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

/** images */
/**************************************/
img, picture {
  border: none;
  display: block;
  vertical-align: bottom;
  width: 100%;
  height: auto;

  backface-visibility: hidden;
}

/** anchor */
/**************************************/
a {
  outline: none;
}


/** pre */
/**************************************/
pre {
  border: 1px solid #bfbfbf;
  padding: 5px;
}

/** .gist */
/**************************************/
.gist {
  width: 100%;
}
