img {
	max-width: 100%;
}

html {
	width: 100%;
	height: 100%;
}

html.overflow-hidden {
  overflow: hidden;
}

body {
	width: 100%;
	height: 100%;
	min-height: 100%;
	font: 15px "Noto Serif JP", "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  font-weight: 400;
	color: $black;
	line-height: 1.8;
	letter-spacing: 0.01em;
}

body.sans {
  font-family: "Noto Sans JP", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}

body * {
	box-sizing: border-box;
}

a {
	color: $black;
	text-decoration: none;
}

:focus {
  outline: none;
}

.group,
.clear {
	zoom:1;
}

.group:after,
.clear:after {
  content: "";
  display: block;
  clear: both;
}
