.fade-enter-active, .fade-leave-active {
  transition: opacity 1.2s $ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  .loadingLogo {
    transform: translatex(-50px);
  }
  .loadingBar {
    transform: translateX(50px);
  }
}

.l-wrapper {
  width: 100%;
  padding-top: 105px;
  @include onlyMobile {
    padding-top: 73px;
  }
}

.l-section {
  padding: 80px 30px;
  @include onlyMobile {
    padding: 60px 15px;
  }
  &-inner {
    max-width: 1440px;
    margin: 0 auto;
    &-small {
      max-width: 1080px;
    }
  }
  &-bluelight {
    background: $bluelightbg;
  }
  &-yellowlight {
    background: $yellowlightbg;
  }
  &-graylight {
    background: $graylightbg;
  }
  &-redlight {
    background: $redlight;
  }
  &-nopadding {
    padding: 0;
  }
}

.u-list-number {
  margin-bottom: 30px;
  padding-left: 1em;
  list-style: decimal;
  list-style-position: outside;
  @include onlyMobile {
      font-size: 13px;
  }
  &.u-mb-small {
      margin-bottom: 15px;
  }
  li {
      margin-bottom: 7px;
      position: relative;
      line-height: 1.5;
      &:last-of-type {
          margin-bottom: 0;
      }
      &.u-lineheight-normal {
          line-height: 1.8;
      }
  }
}

.l-content {
  p {
    text-align: justify;
    text-justify: inter-ideograph;
  }
}