$ease: cubic-bezier(0.22,0.61,0.36,1);;
$white: #fff;
$black: #000;
$blackhover: lighten($black, 20%);
$blackhover2: lighten($black, 10%);
$blackhover3: lighten($black, 40%);
$blacklight: lighten($black, 20%);
$gray: #292929;
$grayhover: lighten($black, 15%);
$gray2: lighten($black, 40%);
$graylight: lighten($black, 92%);
$graylight2: lighten($black, 75%);
$graylight3: lighten($black, 10%);
$graylight4: lighten($black, 78%);
$graylightbg: #f3f4f5;
$red: #e64f4f;
$redbg: #fceded;
$redlight: #faf3ee;
$pink: #dd6a7a;
$pinklight: lighten($pink, 5%);
$pinkdark: darken($pink, 5%);
$rosso: #D9073D;
$rossolight: lighten($rosso, 5%);


$bluedark: #043459;
$bluehover: lighten($bluedark, 15%);
$bluelight: lighten($bluedark, 78%);
$bluelight2: lighten($bluedark, 15%);
$bluelightbg: lighten($bluedark, 79%);
$yellow: #bf8e01;
$yellowhover: lighten($yellow, 5%);
$yellowlight: lighten($yellow, 5%);
$yellowlight2: lighten($yellow, 15%);
$yellowlight3: lighten($yellow, 25%);
$yellowlight4: lighten($yellow, 35%);
$yellowlight5: lighten($yellow, 50%);
$yellowlightbg: lighten($yellow, 58%);

